import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-space-prices-form',
	templateUrl: './space-prices-form.component.html',
	styleUrls: ['./space-prices-form.component.scss']
})
export class SpacePricesFormComponent implements OnInit, OnChanges {

	@Input() form: UntypedFormGroup

	@Input() source: string

	isParkopedia: boolean = false

	constructor() { }

	ngOnInit(): void {}

	get tariffs(): UntypedFormArray { 
		return this.form.get('tariffPrices') as UntypedFormArray; 
	}

	ngOnChanges(changes: SimpleChanges) {
        if ( changes['source'] ) {
			this.isParkopedia = this.source === 'PARKOPEDIA' ?  true : false
        }
    }

}
