import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-clock',
    templateUrl: './clock.component.html',
    styleUrls: ['./clock.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockComponent {

    public time$: Observable<string> = timer(0, 1000).pipe(map(() => moment().format('DD.MM.YYYY | HH:mm:ss')));

}
