import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColumnSetsStateModel } from '../states/space-column-sets.action';
import { Space } from '../space.interface';
import { ColumnSetsState } from '../states/space-column-sets.state';
import { SpaceColumnSetsService } from '../services/space-column-sets.service';
import { SpaceParameterSelected, SpaceParametersModel } from '../states/space-filter.action';
import { SpaceParameterState } from '../states/space-filter.state';
import { EventService } from '@shared/services/event.service';
import { BulkEdit } from '@shared/components/bulk-edit-button/bulk-edit.interface';

@Component({
	selector: 'app-space-list',
	templateUrl: './space-list.component.html',
	styleUrls: ['./space-list.component.scss']
})
export class SpaceListComponent implements OnInit, OnDestroy {

	displayedColumns: string[];

	dataSource = new MatTableDataSource<Space[]>();

	selection = new SelectionModel<Space[]>(true, []);

	spacesCount: number;

	params: any

	bulkedit: BulkEdit = { entity: 'spaces', options: ['publish', 'visibility', 'channels', 'delete'] }

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	@Select(ColumnSetsState) ColumnSetsState$: Observable<ColumnSetsStateModel>
	@Select(SpaceParameterState) SpaceParameterState$: Observable<SpaceParametersModel>

	destroy: Subject<boolean> = new Subject<boolean>();

	constructor(
		@Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) private paginatorDefaults: MatPaginator,
		private dataService: DataService,
		private eventService: EventService,
		private columnService: SpaceColumnSetsService,
		private store: Store,
	) { }


	ngOnInit(): void {
		// Select current filter params
		this.params = this.store.selectSnapshot(SpaceParameterState.getCurrentParams);
		// If state not set, dispatch defaults
		if ( !this.params ) {
			this.store.dispatch(new SpaceParameterSelected({
				size: this.paginatorDefaults.pageSize,
				page: 0
			}))
		}
		// Subscribe to FilterParamater state and get Spaces
		this.SpaceParameterState$
			.pipe(
				takeUntil(this.destroy)
			)
			.subscribe((data: SpaceParametersModel) => { 
				// Clear all checkboxes because of serverside pagination
				this.selection.clear();
				// Using spread because data is a read only object
				this.params = {...data}
				
				this.updateSpaces();
			})
		// Subscribe to ColumnSets state and set displayed columns
		this.ColumnSetsState$
			.pipe(takeUntil(this.destroy))
			.subscribe((data: ColumnSetsStateModel) => { 
				this.displayedColumns = this.getCurrentColumnSet(data) 
			})
		// Subscribe to child event from
		this.eventService.eventListner()
			.pipe(takeUntil(this.destroy))
			.subscribe((data: any) => {
				this.updateSpaces();
				// Clear selection
				this.selection.clear()
		})

	}


	ngOnDestroy(): void {
		// Unsubscribe
		this.destroy.next(true);
		this.destroy.unsubscribe();
	}

	updateSpaces(): void {
		this.dataService.getSpaces(this.params).subscribe((data: any) => {
			this.spacesCount = data.totalElements;
			this.dataSource.data = data.content;
			// Has to be due to lifecycle timing issues, maybe there is a better workaround
			setTimeout(() => {
				this.paginator.length = data.totalElements
				this.paginator.pageIndex = this.params.page
			})
		})
	}

	/** Get displayed columns of the table */
	getCurrentColumnSet(state: ColumnSetsStateModel): string[] {
		if ( state ) {
			if ( state.baseData ) return this.columnService.getBaseDataColumns();
			if ( state.tariffControl ) return this.columnService.getTariffControlColumns();
			if ( state.tariffPrices ) return this.columnService.getTariffPricesColumns();
			if ( state.tariffInfos ) return this.columnService.getTariffInfosColumns();
		}

		return this.columnService.getBaseDataColumns();
	}

	/** Dispatch paginator event */
	pageChanged(event: any) {
		this.params.size = event.pageSize
		this.params.page = event.pageIndex

		this.store.dispatch(new SpaceParameterSelected(this.params))
	}

	/** Go to first page whenever changes to the filter were made */
	filterChanged() {
		this.paginator.firstPage();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string { // TODO: add <Stations> model
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}


}
