import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { merge as mergeStatic } from 'rxjs';

import { ForkjoinWithProgressService } from '@shared/services/forkjoin-with-progress.service';
import { DataService } from '@core/services/data.service';
import { EventService } from '@shared/services/event.service';

@Component({
	selector: 'app-bulk-visibility-dialog',
	templateUrl: './bulk-visibility-dialog.component.html',
	styleUrls: ['./bulk-visibility-dialog.component.scss']
})
export class BulkVisibilityDialogComponent implements OnInit {

	form: UntypedFormGroup
	progress: number = null
	errors: any

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: UntypedFormBuilder,
		private forkJoinService: ForkjoinWithProgressService,
		private dataService: DataService,
		private eventService: EventService
	) { }

	ngOnInit(): void {

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			visibility: ['ONLINE'],
			visibilityDescription: ['']
		})
	}

	forkJoinWithProgress() {

		const arrayOfObservables = this.data.selected.map((data: any) => {

			data.visibility = this.form.get('visibility').value
			data.visibilityDescription = this.form.get('visibilityDescription').value
			
			switch (this.data.entity) {
				case 'spaces':
					return this.dataService.putSpace(data.id, data, false)
				case 'mobies':
					return this.dataService.putMobi(data.id, data, false)
				default:
					return false
			}
		})

		return this.forkJoinService.forkJoinWithProgress(arrayOfObservables)
	}

	bulkEditVisibility() {

		const result$ = this.forkJoinWithProgress()

		result$.pipe(
			mergeMap(([finalResult, progress]) => mergeStatic(
				progress.pipe(
					tap((value) => { this.progress = value }),
					ignoreElements()
				),
				finalResult
			))
		).subscribe((data: any) => {
			// Filter errors from result
			this.errors = data.filter((item: any) => item?.isError)
			// Emit data without errors
			this.eventService.emitEvent(data)
		});
	}

}
