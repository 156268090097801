import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MobiParameterState } from '../states/mobi-filter.state';
import { EventService } from '@shared/services/event.service';
import { BulkEdit } from '@shared/components/bulk-edit-button/bulk-edit.interface';
import { MobiParameterSelected, MobiParametersModel } from '../states/mobi-filter.action';
import { Mobi } from '../mobi.interface';

@Component({
	selector: 'app-mobi-list',
	templateUrl: './mobi-list.component.html',
	styleUrls: ['./mobi-list.component.scss']
})
export class MobiListComponent implements OnInit, OnDestroy {

	displayedColumns: string[] = [
		'select',
		'edit',
		'id',
		'station',
		'mobiTypeName',
		'publishingStatus',
		'visibility',
		'channels',
		'mobiNumber',
		'edited'
	];

	dataSource = new MatTableDataSource<Mobi[]>();

	selection = new SelectionModel<Mobi[]>(true, []);

	mobiesCount: number;

	params: any

	bulkedit: BulkEdit = { entity: 'mobies', options: ['publish', 'visibility', 'channels', 'delete'] }

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	@Select(MobiParameterState) MobiParameterState$: Observable<MobiParametersModel>

	destroy: Subject<boolean> = new Subject<boolean>();

	constructor(
		@Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) private paginatorDefaults: MatPaginator,
		private dataService: DataService,
		private eventService: EventService,
		private store: Store,
	) { }


	ngOnInit(): void {
		// Select current filter params
		this.params = this.store.selectSnapshot(MobiParameterState.getCurrentParams);
		// If state not set, dispatch defaults
		if (!this.params) {
			this.store.dispatch(new MobiParameterSelected({
				size: this.paginatorDefaults.pageSize,
				page: 0
			}))
		}
		// Subscribe to FilterParamater state and get Mobies
		this.MobiParameterState$
			.pipe(
				takeUntil(this.destroy)
			)
			.subscribe((data: MobiParametersModel) => {
				// Clear all checkboxes because of serverside pagination
				this.selection.clear();
				// Using spread because data is a read only object
				this.params = { ...data }

				this.updateMobies();
			})

		// Subscribe to child event from
		this.eventService.eventListner()
			.pipe(takeUntil(this.destroy))
			.subscribe((data: any) => {
				this.updateMobies();
				// Clear selection
				this.selection.clear()
			})

	}

	ngOnDestroy(): void {
		// Unsubscribe
		this.destroy.next(true);
		this.destroy.unsubscribe();
	}

	updateMobies(): void {
		this.dataService.getMobies(this.params).subscribe((data: any) => {
			this.mobiesCount = data.totalElements;
			this.dataSource.data = data.content;
			// Has to be due to lifecycle timing issues, maybe there is a better workaround
			setTimeout(() => {
				this.paginator.length = data.totalElements
				this.paginator.pageIndex = this.params.page
			})
		})
	}


	/** Dispatch paginator event */
	pageChanged(event: any) {
		this.params.size = event.pageSize
		this.params.page = event.pageIndex

		this.store.dispatch(new MobiParameterSelected(this.params))
	}

	/** Go to first page whenever changes to the filter were made */
	filterChanged() {
		this.paginator.firstPage();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string { // TODO: add <Stations> model
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}


}
