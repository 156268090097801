import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { Select, Store } from '@ngxs/store';
import { BulkEdit } from '@shared/components/bulk-edit-button/bulk-edit.interface';
import { EventService } from '@shared/services/event.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Site } from '../site.interface';
import { SiteParameterSelected, SiteParametersModel } from '../states/site-filter.action';
import { SiteParameterState } from '../states/site-filter.state';

@Component({
	selector: 'app-site-list',
	templateUrl: './site-list.component.html',
	styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {

	displayedColumns: string[] = [
		'select',
		'edit',
		'id',
		'title',
		'visibility',
		'station',
		'name',
		'space',
		'protocol',
		'destinationId',
		'isAggregate',
		'isRecord',
	];

	dataSource = new MatTableDataSource<Site[]>();

	selection = new SelectionModel<Site[]>(true, []);

	sitesCount: number;

	params: any

	bulkedit: BulkEdit = { entity: 'sites', options: ['delete'] }

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	@Select(SiteParameterState) SiteParameterState$: Observable<SiteParametersModel>

	destroy: Subject<boolean> = new Subject<boolean>();

	constructor(
		@Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) private paginatorDefaults: MatPaginator,
		private dataService: DataService,
		private eventService: EventService,
		private store: Store,
	) { }


	ngOnInit(): void {
		// Select current filter params
		this.params = this.store.selectSnapshot(SiteParameterState.getCurrentParams);
		// If state not set, dispatch defaults
		if (!this.params) {
			this.store.dispatch(new SiteParameterSelected({
				size: this.paginatorDefaults.pageSize,
				page: 0
			}))
		}
		// Subscribe to FilterParamater state and get Sites
		this.SiteParameterState$
			.pipe(
				takeUntil(this.destroy)
			)
			.subscribe((data: SiteParametersModel) => {
				// Clear all checkboxes because of serverside pagination
				this.selection.clear();
				// Using spread because data is a read only object
				this.params = { ...data }
				// Fetch sites data from api
				this.updateSites();
			})
		// Subscribe to event listiner and get Sites
		this.eventService.eventListner()
			.pipe(takeUntil(this.destroy))
			.subscribe((data: any) => {
				this.updateSites();
				// Clear selection
				this.selection.clear()
			})
	}


	ngOnDestroy(): void {
		// Unsubscribe
		this.destroy.next(true);
		this.destroy.unsubscribe();
	}

	updateSites() {
		this.dataService.getSites(this.params).subscribe((data: any) => {
			this.sitesCount = data.totalElements;
			this.dataSource.data = data.content;
			// Has to be due to lifecycle timing issues, maybe there is a better workaround
			setTimeout(() => {
				this.paginator.length = data.totalElements
				this.paginator.pageIndex = this.params.page
			})
		})
	}

	/** Dispatch paginator event */
	pageChanged(event: any) {
		this.params.size = event.pageSize
		this.params.page = event.pageIndex

		this.store.dispatch(new SiteParameterSelected(this.params))
	}

	/** Go to first page whenever changes to the filter were made */
	filterChanged() {
		this.paginator.firstPage();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string { // TODO: add <Stations> model
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}
}
