<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div>
            <h1 class="mat-display-1">{{mobiesCount}} {{mobiesCount === 1 ? 'Mobilitätsangebot' : 'Mobilitätsangebote'}} </h1>
        </div>
        <div>
            <!-- Import and Export buttons -->
            <app-import-export-buttons [params]="params" entity="mobies"></app-import-export-buttons>
            <!-- Add new mobi button -->
            <button [routerLink]="['/mobi/add']" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Erstellen
            </button>
        </div>
    </div>
    
    <!-- Filter form -->
    <div class="flex flex-row justify-between items-center px-5">
        <app-mobi-filter (filterChanged)="filterChanged()"></app-mobi-filter>
    </div>

    <div class="flex flex-col w-100 px-5">
        
        <!-- Bulk edit and column buttons -->
        <div class="flex flex-row justify-start">
            <app-bulk-edit-button 
                [selection]="selection" 
                [entity]="bulkedit.entity" 
                [options]="bulkedit.options" 
                class="mb-3">
            </app-bulk-edit-button>
        </div>
        
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Edit Mobi Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/mobi/', element.id, element.version]" mat-icon-button color="primary"
                        aria-label="Mobilitätsangebot bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Mobi ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"><code>{{element.id}}</code></td>
            </ng-container>

            <!-- Mobi Internal Name Column -->
            <ng-container matColumnDef="mobiTypeName">
                <th mat-header-cell *matHeaderCellDef> Typ </th>
                <td mat-cell *matCellDef="let element">{{element.mobiTypeName}}</td>
            </ng-container>

            <!-- Mobi Internal Name Column -->
            <ng-container matColumnDef="station">
                <th mat-header-cell *matHeaderCellDef> Bahnhof </th>
                <td mat-cell *matCellDef="let element">{{element.station.name}}</td>
            </ng-container>

            <!-- Mobi Publishing Status Column -->
            <ng-container matColumnDef="publishingStatus">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge" [ngClass]="{
                        'draft':element.publishingStatus === 'DRAFT',
                        'published':element.publishingStatus === 'PUBLISHED',
                        'archived':element.publishingStatus === 'ARCHIVED'
                    }">{{element.publishingStatus | translate }}</span>
                </td>
            </ng-container>

            <!-- Mobi Visibility Column -->
            <ng-container matColumnDef="visibility">
                <th mat-header-cell *matHeaderCellDef> Sichtbarkeit </th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge" [ngClass]="{
                        'online':element.visibility === 'ONLINE',
                        'offline':element.visibility === 'OFFLINE',
                        'pending':element.visibility === 'PENDING',
                        'hidden':element.visibility === 'HIDDEN',
                        'deleted':element.visibility === 'DELETED'
                    }">{{element.visibility | translate }}</span>
                </td>
            </ng-container>

            <!-- Mobi Channels Column -->
            <ng-container matColumnDef="channels">
                <th mat-header-cell *matHeaderCellDef> Ausspielkanal </th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge channel" *ngFor="let channel of element.channels">
                        {{channel}}
                    </span>
                </td>
            </ng-container>

            <!-- Mobi ID Column -->
            <ng-container matColumnDef="mobiNumber">
                <th mat-header-cell *matHeaderCellDef> Mobi-Nr.</th>
                <td mat-cell *matCellDef="let element">{{element.mobiNumber}}</td>
            </ng-container>

            <!-- Mobi Edited Column -->
            <ng-container matColumnDef="edited">
                <th mat-header-cell *matHeaderCellDef> Zuletzt geändert </th>
                <td mat-cell *matCellDef="let element">{{element.edited | date: 'dd.MM.YY - HH:mm'}} Uhr von
                    {{element.editor}}</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">Keine Daten gefunden.</td>
            </tr>

        </table>

        <mat-paginator [length]="mobiesCount" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>
    
</div>