export interface SiteParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
    visibility: string | undefined
}

export class SiteParameterSelected {
    static readonly type = '[Site List] SiteParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class SiteParameterReset {
    static readonly type = '[Site List] SiteParameterReset';
}

