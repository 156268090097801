export interface SpaceParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
    publishingStatus: string | undefined
    publishingTimestamp: string | undefined
    visibility: string | undefined
    channel: string | undefined
    source: string | undefined
    responsibility: string | undefined
}

export class SpaceParameterSelected {
    static readonly type = '[Space List] SpaceParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class SpaceParameterReset {
    static readonly type = '[Space List] SpaceParameterReset';
}

