<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">

    <mat-form-field class="search" appearance="outline" [style.margin-right.px]="16">
        <mat-label>Suche</mat-label>
        <input matInput (keyup)="onKeyup.next($event)" formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Sichtbarkeit</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="visibility">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of visibilityOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Ausspielkanal</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="channel">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of channelOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Quelle</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="source">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of sourceOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Verantwortlichkeit</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="responsibility">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of responsibilityOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <button class="reset-button mat-elevation-z0 mat-border" mat-raised-button aria-label="Reset all filter" matTooltip="Filter zurücksetzen" (click)="resetFilter()">
        <mat-icon>clear</mat-icon>
    </button>

</form>