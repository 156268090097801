import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/services/data.service';
import { ForkjoinWithProgressService } from '@shared/services/forkjoin-with-progress.service';
import { ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { merge as mergeStatic } from 'rxjs';
import { EventService } from '@shared/services/event.service';

@Component({
	selector: 'app-bulk-publish-dialog',
	templateUrl: './bulk-publish-dialog.component.html',
	styleUrls: ['./bulk-publish-dialog.component.scss']
})
export class BulkPublishDialogComponent implements OnInit {

	progress: number = null
	errors: any

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private forkjoin: ForkjoinWithProgressService,
		private dataService: DataService,
		private eventService: EventService
	) { }

	ngOnInit(): void {

	}

	forkJoinWithProgress() {

		const arrayOfObservables = this.data.selected.map((data: any) => {
			switch (this.data.entity) {
				case 'spaces':
					return this.dataService.publishSpace(data.id, data.version, false)
				case 'mobies':
					return this.dataService.publishMobi(data.id, data.version, false)
				default:
					return false
			}
		})

		return this.forkjoin.forkJoinWithProgress(arrayOfObservables)
	}

	bulkEditPublish() {

		const result$ = this.forkJoinWithProgress()

		result$.pipe(
			mergeMap(([finalResult, progress]) => mergeStatic(
				progress.pipe(
					tap((value) => { this.progress = value }),
					ignoreElements()
				),
				finalResult
			))
		).subscribe((data: any) => {
			// Filter error messages
			this.errors = data.filter((item: any) => item?.isError)
			// Emit event
			this.eventService.emitEvent(data)
		})

	}
}
