import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Store } from '@ngxs/store';
import { SpaceParameterReset, SpaceParameterSelected } from 'app/space/states/space-filter.action';
import { SpaceParameterState } from 'app/space/states/space-filter.state';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-space-filter',
  templateUrl: './space-filter.component.html',
  styleUrls: ['./space-filter.component.scss']
})	
export class SpaceFilterComponent implements OnInit {
	
	@Output() filterChanged = new EventEmitter<any>();

	form: UntypedFormGroup

	onKeyup = new Subject<KeyboardEvent>();

	publishingStatusOptions = [
		{'name': 'Entwurf', 'value': 'DRAFT'},
		{'name': 'Publiziert', 'value': 'PUBLISHED'},
		{'name': 'Publiziert am...', 'value': 'ARCHIVED'}
	]
	
	visibilityOptions = [
		{'name': 'Online', 'value': 'ONLINE'},
		{'name': 'Offline', 'value': 'OFFLINE'},
		{'name': 'Prüfen', 'value': 'PENDING'},
		{'name': 'Gelöscht', 'value': 'DELETED'},
		{'name': 'Versteckt', 'value': 'HIDDEN'}
	];

	channelOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBP'},
		{'name': 'Parken am Bahnhof', 'value': 'PAB'},
		{'name': 'DataHub', 'value': 'DATAHUB'},
		{'name': 'OpenData', 'value': 'OPENDATA'},
		{'name': 'PDF', 'value': 'PDF'},
		{'name': 'PIT', 'value': 'PIT'},
		{'name': 'bahn.de', 'value': 'BAHN'},
		{'name': 'bahnhof.de', 'value': 'BAHNHOF'},
		{'name': 'Kundenfeedback', 'value': 'FEEDBACK'},
		// {'name': 'Dashboard', 'value': 'DASHBOARD'}
	];

	sourceOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBAHNPARK'},
		{'name': 'Parkopedia', 'value': 'PARKOPEDIA'},
	];

	responsibilityOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBAHNPARK'},
		{'name': 'Partner', 'value': 'PARTNER'},
		{'name': 'Fremd', 'value': 'FOREIGN'}
	]

	constructor(
		private formBuilder: UntypedFormBuilder,
		private store: Store
	) { }

	ngOnInit(): void {

		this.form = new UntypedFormGroup({});

        this.form = this.formBuilder.group({
			name: new UntypedFormControl(''),
			publishingStatus: new UntypedFormControl(''),
			publishingTimestamp: new UntypedFormControl(''),
            visibility: new UntypedFormControl(''),
			channel: new UntypedFormControl(''),
			source: new UntypedFormControl(''),
			responsibility: new UntypedFormControl('')
        })

		this.form.patchValue(
			this.store.selectSnapshot(SpaceParameterState.getCurrentParams)
		)

		this.onKeyup.pipe(
			map((event: any) => event.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(() => { 
			this.onSelect() 
		});
		
	}

	onSelect(event: any = '') {
		if ( this.form.get('publishingStatus').value === 'ARCHIVED' ) {
			if ( this.form.get('publishingTimestamp').value ) {
				const publishingTimestamp = moment(event.value).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
				this.form.get('publishingTimestamp').patchValue(publishingTimestamp);
				this.filterChanged.emit()
				this.store.dispatch(new SpaceParameterSelected(this.form.value))
			}
		} else {
			// Reset "publishingTimestamp"
			this.form.get('publishingTimestamp').patchValue(null);
			this.filterChanged.emit()
			this.store.dispatch(new SpaceParameterSelected(this.form.value))
		}
	}

	resetFilter() {
		this.form.reset();
		this.store.dispatch(new SpaceParameterReset())
	}

}
