import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationError, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { User } from '../user.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Subscription } from 'rxjs';

// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss'],
  providers: [
	  {
		  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
		  useValue: appearance
	  }
  ]
})
export class UserAddEditComponent implements OnInit, OnDestroy {

	userId: number;
	user: User;
	isAddMode: boolean;

	changesDetected: boolean = false;
	snackOptions: any = { duration: 5000, panelClass: 'success-snack' };

	hideVisibility: boolean = true;

	form: UntypedFormGroup;

	parkspotter: User[];
	isLoadingParkspotter: boolean = false;

	roleOptions = [
		{'name': 'Administrator', 'value': 'ADMIN'},
		{'name': 'Manager', 'value': 'MANAGER'},
		{'name': 'Benutzer', 'value': 'USER'},
		{'name': 'Orange', 'value': 'ORANGE'},
		{'name': 'PIT', 'value': 'PIT'},
		{'name': 'Mobilitätsangebote', 'value': 'MOBI'},
		{'name': 'Dispatcher', 'value': 'DISPATCHER'},
		{'name': 'Parkspotter', 'value': 'PARKSPOTTER'},
	];

	statusOptions = [
		{'name': 'Aktiv', 'value': 'ACTIVE'},
		{'name': 'Blockiert', 'value': 'BLOCKED'},
		{'name': 'Gesperrt', 'value': 'LOCKED'},
		{'name': 'Eingeladen', 'value': 'PENDING'},
		{'name': 'Gelöscht', 'value': 'DELETED'},
	];


	channelOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBP'},
		{'name': 'Parken am Bahnhof', 'value': 'PAB'},
		{'name': 'OpenData', 'value': 'OPENDATA'},
		{'name': 'PDF', 'value': 'PDF'},
		{'name': 'PIT', 'value': 'PIT'},
		{'name': 'bahn.de', 'value': 'BAHN'},
		{'name': 'bahnhof.de', 'value': 'BAHNHOF'},
		{'name': 'Kundenfeedback', 'value': 'FEEDBACK'},
		{'name': 'Dashboard', 'value': 'DASHBOARD'}
	];

	salutationOptions = [
		{ 'name': 'Frau', 'value': 'MS' },
		{ 'name': 'Herr', 'value': 'MR' },
		{ 'name': 'Divers', 'value': 'DIVERSE' },
		{ 'name': 'Keine', 'value': 'NONE' },
	]

	displayedColumns: string[] = [
		'edit',
		'userStatus',
		'username',
		'fullname'
	]

	dataSource = new MatTableDataSource<User[]>();

	pageSize: number = 5; // Set your initial page size

	parkspotterCount: number = 0;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	private routeSubscription: Subscription;

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
	) { }

	ngOnInit(): void {

		this.routeSubscription = this.route.params.subscribe(params => {
		
			this.userId = params['id'];
			this.isAddMode = !this.userId;

			this.form = new UntypedFormGroup({});

			this.form = this.formBuilder.group({
				role: new UntypedFormControl('', [Validators.required]),
				userStatus: new UntypedFormControl('ACTIVE'),
				channel: new UntypedFormControl(null),
				emailAddress: new UntypedFormControl('', [Validators.email]),
				username: new UntypedFormControl('', [Validators.required]),
				password: new UntypedFormControl(null),
				salutation: new UntypedFormControl(null),
				firstname: new UntypedFormControl(''),
				lastname: new UntypedFormControl('')
			})

			if (!this.isAddMode) {
				this.dataService.getUser(this.userId).subscribe((data: any) => {
					this.user = data;
					this.form.patchValue(data)
					// Detect form changes after values patched
					this.form.valueChanges.subscribe(() => { this.changesDetected = true })
					// If user is Dispatcher fetch all associated parkspotter
					if ( this.user.role === 'DISPATCHER' ) {
						this.getAssociatedParkspotter(this.user.id)
					}
				});
			};
		});
	}

	ngOnDestroy(): void {
		this.routeSubscription.unsubscribe();
	}

	onSubmit() {
		if (this.form.invalid) return;

		if (this.isAddMode) this.addUser();
		else this.editUser();
	}

	private addUser() {
		this.dataService.postUser(this.form.value).subscribe((data: any) => {
			this.router.navigate(['/user', data.id]);
			this.snackBar.open('Benutzer erfolgreich erstellt.', null, this.snackOptions);
		})
	}

	private editUser() {
		this.dataService.putUser(this.userId, this.form.value).subscribe((data: any) => {
			this.user = data;
			this.snackBar.open('Benutzer erfolgreich aktualisiert.', null, this.snackOptions);
		})
	}

	changeUserStatus(status: string): void {
		this.form.get('userStatus').setValue(status);
		this.editUser();
	}

	deleteUser() {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `${this.user.username}</b> endgültig löschen?`,
				text: `Sind Sie sicher, dass Sie diesen Benutzer löschen wollen? Wenn ja, tippen Sie <code>LÖSCHEN</code> in Großbuchstaben in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN.`,
				confirmation: `LÖSCHEN`
			}
        });

		dialogRef.afterClosed().subscribe(res => {
			// console.log(res)
			if ( res.data === 'confirmed' ) {
				this.dataService.deleteUser(this.userId).subscribe(() => {
					this.snackBar.open('Benutzer erfolgreich gelöscht.', null, this.snackOptions);
					this.router.navigate(['/user']);
				})
			}	
		});
	}

	getAssociatedParkspotter(id: number): void {
		this.isLoadingParkspotter = true
		this.dataService.getUsers({role: 'PARKSPOTTER'}).subscribe({
			next: (data: any) => {

				this.dataSource.paginator = this.paginator;

				this.dataSource.sort = this.sort;

				this.dataSource.data = data.content.filter((user: User) => user.dispatcherId === id);
				this.parkspotterCount = this.dataSource.data.length;
			},
			error: (err) => {
				console.log(err)
			}, 
			complete: () => {
				this.isLoadingParkspotter = false
			}
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
