import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DataService } from '@core/services/data.service';
import { Unit } from 'app/unit/unit.interface';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
	selector: 'space-autocomplete-units',
	templateUrl: './space-autocomplete-units.component.html',
	styleUrls: ['./space-autocomplete-units.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective }
	]
})
export class SpaceAutocompleteUnitsComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

	@Input() isRequired: boolean

	unit: UntypedFormGroup;

	filteredUnits: Observable<Unit[]>;

	subscription: Subscription;

	hasChanged: boolean = false

	constructor(
		private dataService: DataService,
		private rootForm: FormGroupDirective
	) { }

	ngOnInit(): void {
		this.unit = this.rootForm.control.get('unit') as UntypedFormGroup
	}

	ngAfterViewInit() {
		/** Empty input if no option was selected, this triggers required validator
		 *  and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e) => {
			if ( !(e && e.source) && this.hasChanged ) {
				// this.unit.reset(); kills validation
				this.unit.get('name').patchValue('', { emitEvent: false });
				this.unit.get('name').markAsUntouched();
				this.unit.get('name').markAsPristine();
				this.unit.get('name').updateValueAndValidity();
				
				this.unit.get('id').patchValue(null, { emitEvent: false });

				this.autocompleteTrigger.closePanel();
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	selectionChange(id: number) {
		// patch the id to unit object
		this.unit.get('id').patchValue(id)
		// reset change detector
		this.hasChanged = false
	}

	onKeyup() {
		// changes has been made by user
		this.hasChanged = true
		// filter spaces
		this.filteredUnits = this.unit.valueChanges.pipe(
			startWith(this.unit.value),
			debounceTime(500),
			distinctUntilChanged(), // validation issues when value empty
      		switchMap((value: any) => this._filter(value || ''))
		)
	}

	private _filter(value: string | Unit): Observable<Unit[]> {
		const filterValue = (typeof value === 'string' ? value : value.name).toLowerCase() || '';
		let params = { size: 10, ...(filterValue && {name: filterValue}) }
		return this.dataService.getUnits(params).pipe(
			map((data: any) => data.content.filter((item: any) => item.name.toLowerCase().includes(filterValue)))
		)
	}

	/*displayFn(unit: Unit | any): string | undefined {
		console.log('displayFN', unit)
		return unit.name ? unit.name : unit;
		return unit && unit.name ? unit.name : '';
	}*/

}
