import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobi',
  templateUrl: './mobi.component.html',
  styleUrls: ['./mobi.component.scss']
})
export class MobiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
