import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { Space } from 'app/space/space.interface';
import { from, Observable, of } from 'rxjs';
import { debounceTime, map, mergeMap, startWith, switchMap, toArray } from 'rxjs/operators';

@Component({
	selector: 'app-assignments-autocomplete',
	templateUrl: './assignments-autocomplete.component.html',
	styleUrls: ['./assignments-autocomplete.component.scss']
})
export class AssignmentsAutocompleteComponent implements OnInit{

	spaceCtrl = new UntypedFormControl();
	userId: number;
	filteredSpaces: Observable<Space[]>;
	selectedSpaces: Space[] = [];
	snackBarOptions: any = { duration: 5000, panelClass: 'success-snack' };

	@ViewChild('spaceInput') spaceInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;

	constructor(
		private route: ActivatedRoute,
		private dataService: DataService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {

		this.userId = this.route.snapshot.params['id'];

		if (this.userId) this.getSpacesFromAssignmentIds();

		this.filteredSpaces = this.spaceCtrl.valueChanges.pipe(
			startWith(this.spaceCtrl.value),
			debounceTime(500),
			// distinctUntilChanged(), // validation issues when value empty
			switchMap((value: any) => {
				if (value && value.length) return this._filter(value || '');
				return of([]);
			})
		)
	}

	getSpacesFromAssignmentIds() {
		this.dataService.getUserSpaceAssignments(this.userId).pipe(
			mergeMap((ids: number[]) => {
				return from(ids);
			}),
			mergeMap((id: number) => {
				return this.dataService.getSpaceById(id);
			}),
			toArray()
		)
		.subscribe((spaces: any) => this.selectedSpaces = spaces)
	}

	remove(space: Space): void {
		this.selectedSpaces = this.selectedSpaces.filter((selectedSpace: Space) => selectedSpace.id !== space.id);
		this.dataService.removeUserSpaceAssignments(this.userId, [space.id]).subscribe(() => {
			this.snackBar.open('Parkraum entfernt.', null, this.snackBarOptions);
		});
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedSpaces.push(event.option.value)
		this.spaceInput.nativeElement.value = '';
		this.spaceCtrl.setValue(null);

		const spacesIds: number[] = this.selectedSpaces.map((space: Space) => space.id);
		this.dataService.addUserSpaceAssignments(this.userId, spacesIds).subscribe(() => {
			this.snackBar.open('Parkraum zugewiesen.', null, this.snackBarOptions);
		});
	}

	deleteAll() {
		this.dataService.removeAllUserSpaceAssignments(this.userId).subscribe(() => {
			this.selectedSpaces = []
			this.snackBar.open(
				'Alle zugewiesenen Parkräume entfernt.', 
				null, 
				this.snackBarOptions
			);
		});
	}

	private _filter(value: string | Space): Observable<Space[]>  {
		const filterValue = (typeof value === 'string' ? value : value.name).toLowerCase() || '';
		const params = { 
			size: 10, 
			visibility: 'ONLINE',
			source: 'DBBAHNPARK',
			name: filterValue
		}
		return this.dataService.getSpaces(params).pipe(
			map((data: any) => data.content.filter((item: any) => item.name.toLowerCase().includes(filterValue)))
		)
	}
}
