import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/services/data.service';
import { ForkjoinWithProgressService } from '@shared/services/forkjoin-with-progress.service';
import { Space } from 'app/space/space.interface';
import { ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { merge as mergeStatic } from 'rxjs';
import { EventService } from '@shared/services/event.service';
import { Channel } from 'app/channel/channel.interface';

@Component({
	selector: 'app-bulk-channels-dialog',
	templateUrl: './bulk-channels-dialog.component.html',
	styleUrls: ['./bulk-channels-dialog.component.scss']
})
export class BulkChannelsDialogComponent implements OnInit {

	form: UntypedFormGroup
	progress: number = null
	errors: any
	channels: Channel[]
	selectedChannels: string[]

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private forkjoin: ForkjoinWithProgressService,
		private dataService: DataService,
		private eventService: EventService,
		private formBuilder: UntypedFormBuilder,
	) { }

	ngOnInit(): void {
		// Get all channels - depricated Endpoint
		this.getChannels();
		// Merge spaces from selected spaces
		this.selectedChannels = this.mergeChannels();

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			channels: [this.selectedChannels]
		})

	}

	forkJoinWithProgress() {

		const arrayOfObservables = this.data.selected.map((data: any) => {
			data.channels = this.form.get('channels').value
			switch (this.data.entity) {
				case 'spaces':
					return this.dataService.putSpace(data.id, data, false)
				case 'mobies':
					return this.dataService.putMobi(data.id, data, false)
				default:
					return false
			}
		})

		return this.forkjoin.forkJoinWithProgress(arrayOfObservables)
	}

	bulkEditChannels() {

		const result$ = this.forkJoinWithProgress()

		result$.pipe(
			mergeMap(([finalResult, progress]) => mergeStatic(
				progress.pipe(
					tap((value) => { this.progress = value }),
					ignoreElements()
				),
				finalResult
			))
		).subscribe((data: any) => {
			// Filter errors from result
			this.errors = data.filter((item: any) => item?.isError)
			// Emit spaces without errors
			this.eventService.emitEvent(data)
		})

	}

	getChannels(): void {
		/** Deprecated endpoint */
		this.dataService.getChannels().subscribe((data: Channel[]) => {
			this.channels = data.map((item: Channel) => ({
				token: item.token,
				description: item.description,
				checked: false
			}));
		});
	}

	mergeChannels(): string[] {
		// Return channels from selected spaces, then merge to all existing channels
		const arrayOfChannels = this.data.selected.map((data: any) => {
			return data.channels
		})
		// Flatten array of arrays to array of strings
		const mergedChannels = [].concat(...arrayOfChannels);
		// Remove duplicates
		const uniqueChannels = [...new Set(mergedChannels)];
		// Retrun array of unique channels
		return uniqueChannels

	}
}
