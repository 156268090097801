<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div class="flex-item">
            <h1 class="mat-display-1">{{spacesCount}} Parkräume</h1>
        </div>
        <div class="flex-item">
            <!-- Import and Export buttons -->
            <app-import-export-buttons [params]="params" entity="spaces"></app-import-export-buttons>
            <!-- Add new space button -->
            <button [routerLink]="['/space/add']" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Erstellen
            </button>
        </div>
    </div>
    
    <!-- Filter form -->
    <div class="flex flex-row justify-between items-center px-5">
        <app-space-filter (filterChanged)="filterChanged()"></app-space-filter>
    </div>

    <div class="flex flex-col w-100 px-5">
        
        <!-- Bulk edit and column buttons -->
        <div class="flex flex-row justify-start">
            <app-space-columnset-button class="mb-3 mr-3"></app-space-columnset-button>
            <app-bulk-edit-button 
                [selection]="selection" 
                [entity]="bulkedit.entity" 
                [options]="bulkedit.options" 
                class="mb-3">
            </app-bulk-edit-button>
        </div>
            
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Edit Space Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/space/', element.id, element.version]" mat-icon-button color="primary"
                        aria-label="Parkraum bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Space ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"><code>{{element.id}}</code></td>
            </ng-container>

            <!-- Space Title Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Titel </th>
                <td mat-cell *matCellDef="let element">{{element.title}}</td>
            </ng-container>

            <!-- Base Data Column Set Start -->

                <!-- Space Internal Name Column -->
                <ng-container matColumnDef="nameInternal">
                    <th mat-header-cell *matHeaderCellDef> Name Intern </th>
                    <td mat-cell *matCellDef="let element">{{element.nameInternal}}</td>
                </ng-container>

                <!-- Space Publishing Status Column -->
                <ng-container matColumnDef="publishingStatus">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge" [ngClass]="{
                            'draft':element.publishingStatus === 'DRAFT',
                            'published':element.publishingStatus === 'PUBLISHED',
                            'archived':element.publishingStatus === 'ARCHIVED'
                        }">{{element.publishingStatus | translate }}</span>
                    </td>
                </ng-container>

                <!-- Space Visibility Column -->
                <ng-container matColumnDef="visibility">
                    <th mat-header-cell *matHeaderCellDef> Sichtbarkeit </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge" [ngClass]="{
                            'online':element.visibility === 'ONLINE',
                            'offline':element.visibility === 'OFFLINE',
                            'pending':element.visibility === 'PENDING',
                            'hidden':element.visibility === 'HIDDEN',
                            'deleted':element.visibility === 'DELETED'
                        }">{{element.visibility | translate }}</span>
                    </td>
                </ng-container>

                <!-- Space Channels Column -->
                <ng-container matColumnDef="channels">
                    <th mat-header-cell *matHeaderCellDef> Ausspielkanal </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge channel" *ngFor="let channel of element.channels">
                            {{channel}}
                        </span>
                    </td>
                </ng-container>

                <!-- Space Source Column -->
                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef> Quelle </th>
                    <td mat-cell *matCellDef="let element">{{element.source}}</td>
                </ng-container>

                <!-- Space Operator Column -->
                <ng-container matColumnDef="operator">
                    <th mat-header-cell *matHeaderCellDef> Betreiber </th>
                    <td mat-cell *matCellDef="let element">{{element.operator}}</td>
                </ng-container>

                <!-- Space Number Column -->
                <ng-container matColumnDef="spaceNumber">
                    <th mat-header-cell *matHeaderCellDef> Fl.- Nr. </th>
                    <td mat-cell *matCellDef="let element">{{element.spaceNumber}}</td>
                </ng-container>

                <!-- Spaces Number Parking Places Column -->
                <ng-container matColumnDef="numberParkingPlaces">
                    <th mat-header-cell *matHeaderCellDef> Stellplätze </th>
                    <td mat-cell *matCellDef="let element">{{element.numberParkingPlaces}}</td>
                </ng-container>

                <!-- Spaces Facility Type Column -->
                <ng-container matColumnDef="facilityType">
                    <th mat-header-cell *matHeaderCellDef> Parkart </th>
                    <td mat-cell *matCellDef="let element">{{element.facilityType}}</td>
                </ng-container>

                <!-- Spaces Address Column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Adresse </th>
                    <td mat-cell *matCellDef="let element">{{element.address.street}}<br>{{element.address.postalCode}}
                        {{element.address.city}}</td>
                </ng-container>

                <!-- Spaces Edited Column -->
                <ng-container matColumnDef="edited">
                    <th mat-header-cell *matHeaderCellDef> Zuletzt geändert </th>
                    <td mat-cell *matCellDef="let element">{{element.edited | date: 'dd.MM.YY - HH:mm'}} Uhr von
                        {{element.editor}}</td>
                </ng-container>

            <!-- Base Data Column Set End -->

            <!-- Tariff Control Column Set Start -->

                <!-- Spaces Tariff Flags Column -->
                <ng-container matColumnDef="isDiscountDbParkAndRail">
                    <th mat-header-cell *matHeaderCellDef> Tageskarte Park & Rail </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>{{element.tariffFlags.isDiscountDbParkAndRail ? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Flags Column -->
                <ng-container matColumnDef="isDiscountDbBahnComfort">
                    <th mat-header-cell *matHeaderCellDef> Tageskarte Bahn Comfort </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>{{element.tariffFlags.isDiscountDbBahnComfort ? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Flags Column -->
                <ng-container matColumnDef="isDiscountPCard">
                    <th mat-header-cell *matHeaderCellDef> Tageskarte PCard-Rabattierung </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>{{element.tariffFlags.isDiscountPCard ? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Flags Column -->
                <ng-container matColumnDef="isMonthVendingMachine">
                    <th mat-header-cell *matHeaderCellDef> Monatskarten am Parkscheinautomaten </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>{{element.tariffFlags.isMonthVendingMachine ? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Flags Column -->
                <ng-container matColumnDef="isMonthParkAndRide">
                    <th mat-header-cell *matHeaderCellDef> P+R-Monatskarten im Reisezentrum </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>{{element.tariffFlags.isMonthParkAndRide ? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Flags Column -->
                <ng-container matColumnDef="isMonthSeason">
                    <th mat-header-cell *matHeaderCellDef> Dauerparkvertrag (3 Monate) </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>{{element.tariffFlags.isMonthSeason ? 'done' : 'close'}}</mat-icon>
                    </td>
                </ng-container>

            <!--Tariff Control Column Set End -->

            <!-- Tariff Prices Column Set Start -->

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="20min">
                    <th mat-header-cell *matHeaderCellDef> 20 Minuten </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '20min'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="30min">
                    <th mat-header-cell *matHeaderCellDef> 30 Minuten </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '30min'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1hour">
                    <th mat-header-cell *matHeaderCellDef> 1 Stunde </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1hour'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1day">
                    <th mat-header-cell *matHeaderCellDef> 1 Tag </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === 'day'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1dayPCard">
                    <th mat-header-cell *matHeaderCellDef> 1 Tag Rabatt (PCard)</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1dayPCard'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1week">
                    <th mat-header-cell *matHeaderCellDef> 1 Woche </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1week'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1weekDiscount">
                    <th mat-header-cell *matHeaderCellDef> 1 Woche Rabatt </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1weekDiscount'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1monthVendingMachine">
                    <th mat-header-cell *matHeaderCellDef> 1 Monat </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1monthVendingMachine'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1monthLongTerm">
                    <th mat-header-cell *matHeaderCellDef> 1 Monat Dauerparken (mind. 3 Monate) </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1monthLongTerm'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

                <!-- Spaces Tariff Prices Column // Tariff Prices Columnset  -->
                <ng-container matColumnDef="1monthReservation">
                    <th mat-header-cell *matHeaderCellDef> 1 Monat Dauerparken fester Stellplatz </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let tariff of element.tariffPrices">
                            <span *ngIf="tariff.duration === '1monthReservation'">
                                {{tariff.price | currency : 'EUR'}}
                            </span>
                        </span>
                    </td>
                </ng-container>

            <!-- Tariff Prices Column Set End -->

            <!-- Tariff Infos Column Set Start -->

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffFreeParkingTime">
                    <th mat-header-cell *matHeaderCellDef> Frei Parken (Kiss&Ride) </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffFreeParkingTime}}</td>
                </ng-container>

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffFreeParkingTimeEn">
                    <th mat-header-cell *matHeaderCellDef> Frei Parken (Kiss&Ride) EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffFreeParkingTimeEn}}</td>
                </ng-container>

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffMaxParkingTime">
                    <th mat-header-cell *matHeaderCellDef> Beschränkte Parkdauer </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffMaxParkingTime}}</td>
                </ng-container>

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffMaxParkingTimeEn">
                    <th mat-header-cell *matHeaderCellDef> Beschränkte Parkdauer EN</th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffMaxParkingTimeEn}}</td>
                </ng-container>  

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffIdentifier">
                    <th mat-header-cell *matHeaderCellDef> Tariffkennung </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffIdentifier}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffPointOfSale">
                    <th mat-header-cell *matHeaderCellDef> Sonderprodukte Vorverkauf </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffPointOfSale}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffPointOfSaleEn">
                    <th mat-header-cell *matHeaderCellDef> Sonderprodukte Vorverkauf EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffPointOfSaleEn}}</td>
                </ng-container>
                
                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffDiscount">
                    <th mat-header-cell *matHeaderCellDef> Sonderprodukte Rabattierung </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffDiscount}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffDiscountEn">
                    <th mat-header-cell *matHeaderCellDef> Sonderprodukte Rabattierung EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffDiscountEn}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffSpecial">
                    <th mat-header-cell *matHeaderCellDef> Sondertarif </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffSpecial}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffSpecialEn">
                    <th mat-header-cell *matHeaderCellDef> Sondertarif EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffSpecialEn}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffNotes">
                    <th mat-header-cell *matHeaderCellDef> Hinweis an den Kunden </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffNotes}}</td>
                </ng-container>
                
                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffNotesEn">
                    <th mat-header-cell *matHeaderCellDef> Hinweis an den Kunden EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffNotesEn}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffNotesInternal">
                    <th mat-header-cell *matHeaderCellDef> Allgemeine Bemerkungen (nur intern) </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffNotesInternal}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffRemarks">
                    <th mat-header-cell *matHeaderCellDef> Hinweise zur Tarifbox </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffRemarks}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffRemarksEn">
                    <th mat-header-cell *matHeaderCellDef> Hinweise zur Tarifbox EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffRemarksEn}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffPaymentOptions">
                    <th mat-header-cell *matHeaderCellDef> Zahlungsmedien </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffPaymentOptions}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffPaymentOptionsEn">
                    <th mat-header-cell *matHeaderCellDef> Zahlungsmedien EN </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffPaymentOptionsEn}}</td>
                </ng-container> 

                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffPaymentMobile">
                    <th mat-header-cell *matHeaderCellDef> Mobile Zahlungsarten </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffPaymentMobile}}</td>
                </ng-container>
                
                <!-- Tariff Info Column -->
                <ng-container matColumnDef="tariffPaymentCustomerCards">
                    <th mat-header-cell *matHeaderCellDef> Kundenkarten </th>
                    <td mat-cell *matCellDef="let element">{{element.tariffInfo.tariffPaymentCustomerCards}}</td>
                </ng-container> 

            <!-- Tariff Infos Column Set End -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="15">Keine Daten gefunden.</td>
            </tr>

        </table>

        <mat-paginator [length]="spacesCount" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>

</div>