<h1 mat-dialog-title>Ausspielkanäle bearbeiten</h1>
<mat-dialog-content>
    {{data.selected.length}} markierte Element(e) ändern.

    <form [formGroup]="form">
        <mat-selection-list #channelBulkList formControlName="channels">
            <mat-list-option *ngFor="let channel of channels; let last = last" [value]="channel.token">
                {{channel.token}}<br>
                <mat-hint style="font-size:75%">{{channel.description}}</mat-hint>
                <mat-divider *ngIf="!last"></mat-divider>
            </mat-list-option>
        </mat-selection-list>  
    </form>

    <div *ngIf="progress" [style.margin-top.px]="32" [style.margin-bottom.px]="32">
        <mat-progress-bar [color]="'primary'" [value]="progress"></mat-progress-bar>
        <small>Fortschritt: {{progress}}%</small>
    </div>

    <mat-card class="error-card" *ngIf="errors?.length > 0">
        <ol>
            <li *ngFor="let e of errors">{{e.error.error.message}}</li>
        </ol>
    </mat-card>

    <mat-card class="success-card" *ngIf="errors?.length == 0">
        Massenverarbeitung erfolgreich. Das Fenster kann geschlossen werden.
    </mat-card>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="progress === 100" mat-flat-button mat-dialog-close color="primary">Schließen</button>
    <button *ngIf="progress < 100" mat-flat-button mat-dialog-close>Schließen</button>
    <button *ngIf="progress < 100" mat-flat-button color="primary" (click)="bulkEditChannels()">Speichern</button>
</mat-dialog-actions>