import { Component, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DataService } from '@core/services/data.service';
import { City } from 'app/city/city.interface';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';


@Component({
	selector: 'station-autocomplete-cities',
	templateUrl: './station-autocomplete-cities.component.html',
	styleUrls: ['./station-autocomplete-cities.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective }
	]
})
export class StationAutocompleteCitiesComponent implements OnInit {

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

	city: UntypedFormGroup

	filteredCities: Observable<City[]>;

	subscription: Subscription;

	hasChanged: boolean = false

	constructor(
		private dataService: DataService,
		private rootForm: FormGroupDirective
	) { }

	ngOnInit(): void {
		this.city = this.rootForm.control.get('city') as UntypedFormGroup
		/* Too many api calls, when its here -> onKeyup()
		this.filteredCities = this.city.valueChanges.pipe(
			startWith(this.city.value),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value || ''))
		)
		*/
	}

	ngAfterViewInit() {
		/** Empty input if no option was selected, this triggers required validator
		 *  and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e) => {
			if ( !(e && e.source) && this.hasChanged ) {
				this.city.get('name').patchValue('', { emitEvent: false });
				this.autocompleteTrigger.closePanel();
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	selectionChange(id: number) {
		// patch the id to cities object
		this.city.get('id').patchValue(id)
		// reset change detector
		this.hasChanged = false
	}

	/** That's not how you suppose to do it, but for now it does the job */
	onKeyup() {
		// changes has been made by user
		this.hasChanged = true
		// filter cities
		this.filteredCities = this.city.valueChanges.pipe(
			startWith(this.city.value),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value || ''))
		)
	}

	private _filter(value: string | City): Observable<City[]> {
		let filterValue = (typeof value === 'string' ? value : value.name).toLowerCase();
		let params = { size: 10, ...(filterValue && { name: filterValue }) }
		return this.dataService.getCities(params).pipe(
			map((data: any) => data.content.filter((item: City) => item.name.toLowerCase().includes(filterValue)))
		)
	}

}
