import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '@core/services/data.service';
import { Observable } from 'rxjs';
import { Me } from './me.interface';

@Component({
    selector: 'me-dialog',
    templateUrl: './me-dialog.component.html',
    styleUrls: ['./me-dialog.component.scss']
})
export class MeDialogComponent implements OnInit {

    public me$: Observable<Me>;

    form: UntypedFormGroup;

    hideVisibilityOldPw: boolean = true;
    hideVisibilityNewPw: boolean = true;

    snackOptions:any =  { duration: 5000, panelClass: 'success-snack' };

    constructor(
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.me$ = this.data

        this.form = new UntypedFormGroup({});

        this.form = this.formBuilder.group({
            newPassword: new UntypedFormControl('', [Validators.required]),
            oldPassword: new UntypedFormControl('', [Validators.required])
        })
    }

    onSubmit() {
		if (this.form.invalid) return;

        this.dataService.putMePassword(this.form.value).subscribe((data: any) => {
            // console.log(data)
			this.snackBar.open('Ihr Passwort wurde erfolgreich geändert.', null, this.snackOptions);
		})
	}


}
