export interface UserStateModel {
    channel: string | null
    created: string | null
    username: string | null
    salutation: string | null
    firstname: string | null
    lastname: string | null
    emailAdress: string | null
    role: string | null
    userStatus: string | null
    mustChangPassword: string | null
}

export class SetUser {
    static readonly type = '[User] Set user';
}

export class RemoveUser {
    static readonly type = '[User] Remove user';
}