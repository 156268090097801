<div class="flex flex-col justify-center items-center w-100 h-[calc(100vh-128px)]">

    <div class="flex flex-row flex-grow justify-center items-center">
        <mat-card class="w-96">
            <mat-card-header>
                <div mat-card-avatar class="w-12 h-12 dbbp-logo"></div>
                <mat-card-title>Hallo, {{currentUser.firstname}} {{currentUser.lastname}}!</mat-card-title>
                <mat-card-subtitle>Herzlich Willkommen in der Orange DB</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p class="text-base">Orange Datenbank - Administrationstool</p>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="h-6 text-center">
        <p class="text-base">Made with ❤️ in Berlin.</p>
        <small><code>🍊 API Version {{api?.version}} // 💻 GUI Version {{appVersion}} // 🌎 {{api?.basepath}}</code></small>    
    </div>

</div>


