<mat-toolbar class="sticky top-0 z-50 mat-elevation-z2 flex flex-col justify-between items-center" color="primary">

    <div class="flex flex-row">
        
        <img [routerLink]="'/home'" class="dbbp-logo" src="assets/db-logo.svg" height="30" width="auto"alt="Logo" />
        &nbsp;&nbsp;<span class="me-5">Orange DB</span>

        <div *ngIf="isAuthenticated$ | async">
            <a mat-button [routerLink]="'/city'" routerLinkActive="active" *ngIf="(me$ | async)?.role !== 'MOBI'"> Städte </a>
            <a mat-button [routerLink]="'/station'" routerLinkActive="active"> Bahnhöfe </a>
            <a mat-button [routerLink]="'/unit'" routerLinkActive="active" *ngIf="(me$ | async)?.role !== 'MOBI'"> Parkobjekte </a>
            <a mat-button [routerLink]="'/space'" routerLinkActive="active" *ngIf="(me$ | async)?.role !== 'MOBI'"> Parkräume </a>
            <a mat-button [routerLink]="'/site'" routerLinkActive="active" *ngIf="(me$ | async)?.role !== 'MOBI'"> Parkbereiche </a>
            <a mat-button [routerLink]="'/mobi'" routerLinkActive="active"> Mobilitätsangebote </a>
        </div>
    </div>
        
    <div class="flex flex-row items-center" *ngIf="isAuthenticated$ | async"> 
        
        <!-- Clock -->
        <app-clock class="me-5"></app-clock>
        
        <!-- Administration -->
        <button *ngIf="hasPermission((me$ | async)?.role)" mat-icon-button [matMenuTriggerFor]="adminMenu" aria-label="Administration">
            <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #adminMenu="matMenu">
            <button mat-menu-item [routerLink]="'/user'">
                <mat-icon>people</mat-icon> Benutzer
            </button>
            <button mat-menu-item [routerLink]="'/apikey'">
                <mat-icon>key</mat-icon> API Keys
            </button>
            <button mat-menu-item [routerLink]="'/channel'">
                <mat-icon>sync_alt</mat-icon> Ausspielkanäle
            </button>
        </mat-menu>

        <!-- Me -->
        <button mat-icon-button [matMenuTriggerFor]="meMenu" aria-label="Account" *ngIf="me$">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #meMenu="matMenu">
            <button mat-menu-item aria-label="Benutzer" (click)="openMeDialog()">
                <mat-icon>person</mat-icon> {{(me$ | async)?.username}}
            </button>
            <logout-button></logout-button>
        </mat-menu>

    </div>

</mat-toolbar>