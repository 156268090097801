import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[tariffPrices]'
})
export class TariffPricesDirective {
  
  constructor(private model: NgControl) { }
  
  @HostListener('input') inputChange() {

    const newValue = this.model.value.toFixed(2).replace('.', ',') 
    
    this.model.control.setValue(newValue);
    this.model.valueAccessor.writeValue(newValue);

  }    

}
