<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
    
        <div>
            <h1 class="mat-display-1" *ngIf="isAddMode">Neue Stadt erstellen</h1>
            <h1 class="mat-display-1" *ngIf="!isAddMode">Stadt bearbeiten: <b>{{city?.name}}</b></h1>
            <span class="mat-caption" *ngIf="!isAddMode">
                <b>ID:</b> {{cityId}} |
                <b>Erzeugt:</b> {{city?.created | date: 'dd.mm.yyyy - HH:mm:ss'}} Uhr von {{city?.creator}} |
                <b>Zuletzt geändert:</b> {{city?.edited | date: 'dd.mm.yyyy - HH:mm:ss'}} Uhr von {{city?.editor}}
            </span>
        </div>

        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm">
                SPEICHERN
            </button>
            <button [routerLink]="['/city']" type="button" mat-raised-button class="!ml-[16px]">
                ZURÜCK
            </button>
        </div>

    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards masonry-mat-cards-grid-1 w-1/3 px-5">

            <mat-card>

                <mat-card-title>Stadt</mat-card-title>

                <mat-card-content class="!flex flex-col">

                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>                      

                </mat-card-content>

            </mat-card>

            <mat-card>

                <mat-card-title>Koordinaten</mat-card-title>

                <mat-card-content class="!flex flex-col" formGroupName="geoLocation">                  

                    <mat-form-field>
                        <mat-label>Längengrad</mat-label>
                        <input matInput formControlName="longitude">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Breitengrad</mat-label>
                        <input matInput formControlName="latitude">
                    </mat-form-field>

                </mat-card-content>

            </mat-card>

        </div>

    </form>


</div>


<pre class="hidden fixed z-50 bottom-0 right-0 bg-antiquewhite h-1/2">
    <code>{{this.form.value | json}}</code>
</pre>