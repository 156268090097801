export interface StationParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
    visibility: string | undefined
    channel: string | undefined
    source: string | undefined
    responsibility: string | undefined
}

export class StationParameterSelected {
    static readonly type = '[Station List] StationParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class StationParameterReset {
    static readonly type = '[Station List] StationParameterReset';
}

