import { Inject, Injectable } from '@angular/core';
import { MatPaginator, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { StationParameterReset, StationParameterSelected } from './station-filter.action';

export const defaults: any = {
	size: 25,
	page: 0
}

@State<any>({
	name: 'stationListParams',
	defaults: defaults
})
@Injectable()
export class StationParameterState {

	constructor(
		@Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) private paginatorDefaults: MatPaginator
	) { 
		defaults.size = this.paginatorDefaults.pageSize
	}

	@Selector()
	static getCurrentParams(state: any) {
		return state
	}

	@Action(StationParameterSelected)
	StationParameterSelected(ctx: StateContext<any>, action: StationParameterSelected) {

		const state = ctx.getState();

		let filter = {
			...(action.urlParams.name && {name: action.urlParams.name}),
			// ...(action.urlParams.publishingStatus && {publishingStatus: action.urlParams.publishingStatus}),
			...(action.urlParams.visibility && {visibility: action.urlParams.visibility}),
			...(action.urlParams.channel && {channel: action.urlParams.channel}),
			...(action.urlParams.source && {source: action.urlParams.source}),
			...(action.urlParams.responsibility && {responsibility: action.urlParams.responsibility})
		}
		
		let size, page
		// If size is undefined use current state else patch action
		if ( typeof action.urlParams.size !== 'undefined' ) {
			size =  action.urlParams.size
		} else {
			size = state.size
		}
		// If page is undefined use current state else patch action
		if ( typeof action.urlParams.page !== 'undefined' ) {
			page =  action.urlParams.page
		} else {
			page = state.page
		}

		const pagination = { size: size, page: page }

		ctx.setState({...pagination, ...filter});
	}

	@Action(StationParameterReset)
	StationParameterReset(ctx: StateContext<any>) {
		ctx.setState(defaults);
	}

}