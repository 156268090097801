import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { Observable } from 'rxjs';
import { Unit } from '../unit.interface';

// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@Component({
	selector: 'app-unit-add-edit',
	templateUrl: './unit-add-edit.component.html',
	styleUrls: ['./unit-add-edit.component.scss'],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	]
})
export class UnitAddEditComponent implements OnInit {

	unitId: number;
	unit: Unit;
	isAddMode: boolean;

	changesDetected: boolean = false;
	snackOptions: any = { duration: 5000, panelClass: 'success-snack' };

	form: UntypedFormGroup;

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.unitId = this.route.snapshot.params['id'];
		this.isAddMode = !this.unitId;

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			name: new UntypedFormControl('', [Validators.required]),
			bahnstelle: new UntypedFormControl(''),
			costCenterContipark: new UntypedFormControl('', [Validators.required]),
			regionalDistrict: new UntypedFormControl(null),
			priority: new UntypedFormControl('')
		})

		if (!this.isAddMode) {
			this.dataService.getUnit(this.unitId).subscribe((data: any) => {
				this.unit = data;
				this.form.patchValue(data)
				// Detect form changes after values patched
				this.form.valueChanges.subscribe(() => { this.changesDetected = true })
			});
		};

	}

	onSubmit() {
		if (this.form.invalid) return;

		if (this.isAddMode) this.addUnit();
		else this.editUnit();
	}

	private addUnit() {
		this.dataService.postUnit(this.form.value).subscribe((data: any) => {
			this.router.navigate(['/unit', data.id]);
			this.snackBar.open('Parkobjekt erfolgreich erstellt.', null, this.snackOptions);
		})
	}

	private editUnit() {
		this.dataService.putUnit(this.unitId, this.form.value).subscribe((data: any) => {
			this.unit = data;
			this.snackBar.open('Parkobjekt erfolgreich aktualisiert.', null, this.snackOptions);
		})
	}

}
