<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div class="flex-item">
            <h1 class="mat-display-1">{{unitsCount}} Parkobjekte</h1>
        </div>
        <div class="flex-item">
            <!-- Import and Export buttons -->
            <app-import-export-buttons [params]="params" entity="units"></app-import-export-buttons>
            <!-- Add new Site button -->
            <button [routerLink]="['/unit/add']" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Erstellen
            </button>
        </div>
    </div>

    <!-- Filter form -->
    <div class="flex flex-row justify-between items-center px-5">
        <app-unit-filter (filterChanged)="filterChanged()"></app-unit-filter>
    </div>


    <div class="flex flex-col w-100 px-5">
        
        <!-- Bulk edit and column buttons -->
        <div class="flex flex-row justify-start">
            <app-bulk-edit-button 
                [selection]="selection" 
                [entity]="bulkedit.entity" 
                [options]="bulkedit.options" 
                class="mb-3">
            </app-bulk-edit-button>
        </div>

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Edit Unit Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/unit/', element.id]" mat-icon-button color="primary" aria-label="Parkbereiche bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Unit ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>

            <!-- Unit Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <!-- Unit "Bahnstelle" Column -->
            <ng-container matColumnDef="bahnstelle">
                <th mat-header-cell *matHeaderCellDef> Bahnstelle </th>
                <td mat-cell *matCellDef="let element">{{element.bahnstelle}}</td>
            </ng-container>

            <!-- Unit Regional District Column -->
            <ng-container matColumnDef="regionalDistrict">
                <th mat-header-cell *matHeaderCellDef> Regionalbereich </th>
                <td mat-cell *matCellDef="let element">{{element.regionalDistrict}}</td>
            </ng-container>

            <!-- Unit Cost Center Contipark Column -->
            <ng-container matColumnDef="costCenterContipark">
                <th mat-header-cell *matHeaderCellDef> Kostenstelle </th>
                <td mat-cell *matCellDef="let element">{{element.costCenterContipark}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">Keine Daten für "{{dataSource.filter}}" gefunden.</td>
            </tr> 

        </table>

        <mat-paginator [length]="unitsCount" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>

</div>