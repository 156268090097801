export interface ApikeyParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
    userStatus: string | undefined
    role: string | undefined
}

export class ApikeyParameterSelected {
    static readonly type = '[Apikey List] ApikeyParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class ApikeyParameterReset {
    static readonly type = '[Apikey List] ApikeyParameterReset';
}

