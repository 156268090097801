export interface UnitParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
}

export class UnitParameterSelected {
    static readonly type = '[Unit List] UnitParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class UnitParameterReset {
    static readonly type = '[Unit List] UnitParameterReset';
}

