import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/services/data.service';
import { EventService } from '@shared/services/event.service';

@Component({
	selector: 'app-import-dialog',
	templateUrl: './import-dialog.component.html',
	styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent {

	@Output() onSuccess = new EventEmitter<any>();

	selectedFile: File
	success: any;
	failed: any;

	constructor(
		private dataService: DataService,
		private eventService: EventService,
		@Inject(MAT_DIALOG_DATA) public entity: string,
	) { }

	selectFile(event: any) {
		let files: FileList = event.target.files;
		this.selectedFile = files[0];
	}

	import(): void {
		const formData: FormData = new FormData();
		formData.append('file', this.selectedFile);
		this.dataService.uploadFile(this.entity, formData).subscribe((data: string[]) => {
			this.success = data;
			this.eventService.emitEvent(this.success)
		},
		error => {
			this.failed = error.error
			// console.log(this.failed)
		})
	}


}
