import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@core/services/data.service';
import { ForkjoinWithProgressService } from '@shared/services/forkjoin-with-progress.service';
import { ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { merge as mergeStatic } from 'rxjs';
import { EventService } from '@shared/services/event.service';

@Component({
	selector: 'app-bulk-delete-dialog',
	templateUrl: './bulk-delete-dialog.component.html',
	styleUrls: ['./bulk-delete-dialog.component.scss']
})
export class BulkDeleteDialogComponent implements OnInit {

	progress: number = null
	errors: any

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private forkJoinService: ForkjoinWithProgressService,
		private dataService: DataService,
		private eventService: EventService
	) { }

	ngOnInit(): void { }

	forkJoinWithProgress() {

		const arrayOfObservables = this.data.selected.map((data: any) => {
			switch (this.data.entity) {
				case 'cities':
					return this.dataService.deleteCity(data.id, false)
				case 'stations':
					return this.dataService.deleteStation(data.id, false)
				case 'units':
					return this.dataService.deleteUnit(data.id, false)
				case 'spaces':
					return this.dataService.deleteSpace(data.id, data.version, false)
				case 'mobies':
					return this.dataService.deleteMobi(data.id, data.version, false)	
				case 'sites':
					return this.dataService.deleteSite(data.id, false)	
				case 'users':
					return this.dataService.deleteUser(data.id, false)	
				default:
					return false
			}	
		})

		return this.forkJoinService.forkJoinWithProgress(arrayOfObservables)
	}

	bulkEditDelete() {

		const result$ = this.forkJoinWithProgress()

		result$.pipe(
			mergeMap(([finalResult, progress]) => mergeStatic(
				progress.pipe(
					tap((value) => { this.progress = value }),
					ignoreElements()
				),
				finalResult
			))
		).subscribe((data: any) => {
			// Filter errors from result
			this.errors = data.filter((item: any) => item?.isError)
			// Emit spaces without errors
			this.eventService.emitEvent(data)
		});
	}

}
