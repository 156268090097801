<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
            <div class="flex-item">
                <h1 class="mat-display-1">{{channelsCount}} Ausspielkanäle</h1>
            </div>
            <!--<div class="flex-item">
                <button [routerLink]="['/city/add']" mat-flat-button color="primary">
                    <mat-icon>add</mat-icon> Erstellen
                </button>
            </div>-->
    </div>

    <div class="flex flex-col w-100 px-5">

        <!-- Bulk edit and column buttons 
        <div class="flex flex-row justify-start">
            <app-channel-bulkedit-button [selection]="selection" [style.margin-bottom.px]="16"></app-channel-bulkedit-button>
        </div>
        -->

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">

            <!-- Checkbox Column 
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            -->

            <!-- Edit Channel Column 
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/channel/', element.id]" mat-icon-button color="primary" aria-label="Ausspielkanal bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            -->

            <!-- Channel ID Column
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"><code>{{element.id}}</code></td>
            </ng-container>
                -->

            <!-- Channel Token Column -->
            <ng-container matColumnDef="token">
                <th mat-header-cell *matHeaderCellDef> Token </th>
                <td mat-cell *matCellDef="let element"><code>{{element.token}}</code></td>
            </ng-container>

            <!-- Channel Name Column 
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            -->

            <!-- Channel Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Beschreibung </th>
                <td mat-cell *matCellDef="let element">{{element.description}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11">Keine Daten für "{{dataSource.filter}}" gefunden.</td>
            </tr> 

        </table>

        <mat-paginator [length]="channelsCount" (page)="pageEvent = $event" showFirstLastButtons></mat-paginator>

    </div>

</div>