import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'translate'
})
export class TranslatePipe implements PipeTransform {

	transform(value: string): string {
		// publishingStatus
		if (value === 'DRAFT') return 'Entwurf'
		if (value === 'PUBLISHED') return 'Publiziert'
		if (value === 'ARCHIVED') return 'Archiviert'
		// visibility
		if (value === 'ONLINE') return 'Online'
		if (value === 'OFFLINE') return 'Oflline'
		if (value === 'PENDING') return 'Prüfen'
		if (value === 'DELETED') return 'Gelöscht'
		if (value === 'HIDDEN') return 'Versteckt'
		// userStatus
		if (value === 'ACTIVE') return 'Aktiv'
		if (value === 'BLOCKED') return 'Blockiert'
		if (value === 'PENDING') return 'Eingeladen'
		if (value === 'DELETED') return 'Gelöscht'
		if (value === 'LOCKED') return 'Gesperrt'

		else return value

	}


}
