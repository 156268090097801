<h1 mat-dialog-title [innerHTML]="data.title"></h1>

<div mat-dialog-content>
    
    <p [innerHTML]="data.text"></p>
    
    <mat-form-field appearance="outline" fxLayout="column">
        <mat-label>Tippen Sie "{{data.confirmation}}" in dieses Feld</mat-label>
        <input matInput type="text" [(ngModel)]="confirmationValue" cdkFocusInitial>
    </mat-form-field>

</div>

<div mat-dialog-actions>
    <button mat-button 
            mat-dialog-close>
            ABBRECHEN
    </button>
    <button mat-button 
            mat-dialog-close 
            color="warn"
            [disabled]="confirmationValue !== data.confirmation" 
            (click)="continue()">
            {{data.confirmation}}
    </button>
</div>