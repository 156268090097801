<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div>
            <h1 class="mat-display-1" *ngIf="isAddMode">Neuen Ausspielkanal erstellen</h1>
            <h1 class="mat-display-1" *ngIf="!isAddMode">Ausspielkanal <b>{{channel?.name}}<span *ngIf="changesDetected">*</span></b> bearbeiten</h1>
        </div>

        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm">
                SPEICHERN
            </button>
            <button [routerLink]="['/channel']" type="button" mat-raised-button [style.margin-left.px]="16">
                ZURÜCK
            </button>
        </div>

    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry lg:masonry-lg xl:masonry-xl px-5 pt-1">

            <mat-card>

                <mat-card-title>Ausspielkanal</mat-card-title>

                <mat-card-content class="!flex flex-col">

                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>  
                    
                    <mat-form-field>
                        <mat-label>Token</mat-label>
                        <input matInput formControlName="token" required>
                    </mat-form-field>  

                    <mat-form-field>
                        <mat-label>Beschreibung</mat-label>
                        <textarea matInput cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="10" formControlName="description"></textarea>
                        <mat-hint>Eine Beschreibung für den Ausspielkanal</mat-hint>
                    </mat-form-field>

                </mat-card-content>

            </mat-card>

        </div>

    </form>


</div>


<pre style="display:none;z-index:9999999999;position:fixed;bottom:0px;right:0;background-color: antiquewhite;height:50vh;"><code>{{this.form.value | json}}</code></pre>