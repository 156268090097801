<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">

    <mat-form-field class="search" appearance="outline" [style.margin-right.px]="16">
        <mat-label>Suche nach Bahnhof</mat-label>
        <input matInput (keyup)="onKeyup.next($event)" formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Mobilitäts-Typ</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="mobiType">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of mobiTypeOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Publikationsstatus</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="publishingStatus">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of publishingStatusOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16" *ngIf="form.get('publishingStatus').value === 'ARCHIVED'">
        <mat-label>Datum wählen</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="onSelect($event)" formControlName="publishingTimestamp">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Sichtbarkeit</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="visibility">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of visibilityOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" [style.margin-right.px]="16">
        <mat-label>Ausspielkanal</mat-label>
        <mat-select (selectionChange)="onSelect()" formControlName="channel">
            <mat-option>Alle</mat-option>
            <mat-option *ngFor="let option of channelOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>


    <button class="reset-button mat-elevation-z0 mat-border" mat-raised-button aria-label="Reset all filter" matTooltip="Filter zurücksetzen" (click)="resetFilter()">
        <mat-icon>clear</mat-icon>
    </button>

</form>



