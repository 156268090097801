export interface ColumnSetsStateModel {
    baseData: boolean;
    tariffControl: boolean;
    tariffPrices: boolean;
    tariffInfos: boolean;
}

export class DisplayedColumnsChanged {
    static readonly type = '[Space List] DisplayedColumnsChanged';
    constructor(
        public columnSet: string
    ) {}   
}