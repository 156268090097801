import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DataService } from '@core/services/data.service';
import { Station } from 'app/station/station.interface';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'site-autocomplete-stations',
  templateUrl: './site-autocomplete-stations.component.html',
  styleUrls: ['./site-autocomplete-stations.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective }
	]
})
export class SiteAutocompleteStationsComponent implements OnInit, OnDestroy, AfterViewInit  {

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

	station: UntypedFormGroup

	filteredStations: Observable<Station[]>;

	subscription: Subscription;

	hasChanged: boolean = false

	constructor(
		private dataService: DataService,
		private rootForm: FormGroupDirective
	) { }

	ngOnInit(): void {
		this.station = this.rootForm.control.get('station') as UntypedFormGroup
		/* Too many api calls, when its here -> onKeyup()
		this.filteredStations = this.station.valueChanges.pipe(
			startWith(this.station.value),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value || ''))
		)
		*/
	}

	ngAfterViewInit() {
		/** Empty input if no option was selected, this triggers required validator
		 *  and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e) => {
			if ( !(e && e.source) && this.hasChanged ) {
				this.station.get('name').patchValue('', { emitEvent: false });
				this.autocompleteTrigger.closePanel();
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	selectionChange(id: number) {
		// patch the id to station object
		this.station.get('id').patchValue(id)
		// reset change detector
		this.hasChanged = false
	}

	/** That's not how you suppose to do it, but for now it does the job */
	onKeyup() {
		// changes has been made by user
		this.hasChanged = true
		// filter spaces
		this.filteredStations = this.station.valueChanges.pipe(
			startWith(this.station.value),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap((value: any) => this._filter(value || ''))
		)
	}

	private _filter(value: string | Station): Observable<Station[]> {
		let filterValue = (typeof value === 'string' ? value : value.name).toLowerCase();
		let params = { size: 10, ...(filterValue && {name: filterValue}) }
		return this.dataService.getStations(params).pipe(
			map((data: any) => data.content.filter((item: Station) => item.name.toLowerCase().includes(filterValue)))
		)
	}
}
