import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UserParameterState } from 'app/user/states/user-filter.state';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UserParameterReset, UserParameterSelected } from 'app/user/states/user-filter.action';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit {

	@Output() filterChanged = new EventEmitter<any>();

	form: UntypedFormGroup

	onKeyup = new Subject<KeyboardEvent>();
	
	userStatusOptions = [
		{'name': 'Blockiert', 'value': 'BLOCKED'},
		{'name': 'Aktiv', 'value': 'ACTIVE'},
		{'name': 'Prüfen', 'value': 'PENDING'},
		{'name': 'Gelöscht', 'value': 'DELETED'},
		{'name': 'Gesperrt', 'value': 'LOCKED'}
	];

	roleOptions = [
		// {'name': 'System', 'value': 'SYSTEM'},
		{'name': 'Administrator', 'value': 'ADMIN'},
		{'name': 'Manager', 'value': 'MANAGER'},
		{'name': 'Benutzer', 'value': 'USER'},
		// {'name': 'Orange', 'value': 'ORANGE'},
		{'name': 'Mobilitätsangebote', 'value': 'MOBI'},
		{'name': 'PIT', 'value': 'PIT'},
		{'name': 'Dispatcher', 'value': 'DISPATCHER'},
		{'name': 'Parkspotter', 'value': 'PARKSPOTTER'},
		// {'name': 'Api', 'value': 'API'},
		// {'name': 'Keine Rolle', 'value': 'NOBODY'}
	];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private store: Store
	) { }

	ngOnInit(): void {

		this.form = new UntypedFormGroup({});

        this.form = this.formBuilder.group({
			name: new UntypedFormControl(''),
			role: new UntypedFormControl(''),
			userStatus: new UntypedFormControl('')
        })

		this.form.patchValue(
			this.store.selectSnapshot(UserParameterState.getCurrentParams)
		)

		this.onKeyup.pipe(
			map((event: any) => event.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(() => { 
			this.onSelect() 
		});
		
	}

	onSelect() {
		this.filterChanged.emit()
		this.store.dispatch(new UserParameterSelected(this.form.value))
	}

	resetFilter() {
		this.form.reset();
		this.store.dispatch(new UserParameterReset())
	}


}
