import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EventService {

	private event = new BehaviorSubject<any>([]);

	emitEvent(event: any) {
		this.event.next(event)
	}

	eventListner() {
		return this.event.asObservable();
	}
}
