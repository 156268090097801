import { Observable, of, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from '@core/states/auth/auth.action';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private store: Store
    ) { }

    intercept(
        request: HttpRequest<any>, 
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        request = request.clone({
            withCredentials: true
        });

        return next.handle(request)
  
        .pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
           
                    if ( err.status === 401 || err.status === 403 ) {

                        this.store.dispatch([new Logout()]).subscribe(
                            () => {
                                this.router.navigate(['/'], { replaceUrl:true })
                            }
                        )
                    }
        
                }
                return throwError(err); 
            })
        )

    }
}