<mat-card clas="mb-2">
    <mat-card-content>
        <mat-form-field class="assignment-autocomplete">
            <mat-label>
                Zugewiesene Parkräume ({{selectedSpaces.length}})
            </mat-label>
            <mat-chip-list #chipList aria-label="Space selection">
                <mat-chip *ngFor="let space of selectedSpaces" (removed)="remove(space)">
                    {{space.title}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Parkraum hinzufügen..." 
                    #spaceInput 
                    [formControl]="spaceCtrl" 
                    [matAutocomplete]="assignmentsAuto"
                    [matChipInputFor]="chipList" 
                    (matChipInputTokenEnd)="false">
            </mat-chip-list>
            <mat-autocomplete #assignmentsAuto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let space of filteredSpaces | async" [value]="space">
                    {{space.title}}
                </mat-option>
            </mat-autocomplete>
            <mat-hint class="!text-[#979797]">
                Die Schnittstellen OpenData und OpenData Extended können im Zugriff auf einen oder mehrere Parkräume beschränkt werden. 
                Ist das Feld leer, besteht Zugriff auf alle Parkräume.
            </mat-hint>
        </mat-form-field>
    </mat-card-content>
</mat-card>

<mat-card-actions>
    <button mat-button type="button" color="warn" (click)="deleteAll()" [disabled]="!selectedSpaces.length">
        <mat-icon>remove</mat-icon>
        Alle zugewiesenen Parkräume entfernen
    </button>
</mat-card-actions>

