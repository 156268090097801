<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div class="flex-item">
            <h1 class="mat-display-1">{{stationsCount}} Bahnhöfe</h1>
        </div>
        <div class="flex-item">
            <!-- Import and Export buttons -->
            <app-import-export-buttons [params]="params" entity="stations"></app-import-export-buttons>
            <!-- Add new Site button -->
            <button [routerLink]="['/station/add']" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Erstellen
            </button>
        </div>
    </div>

    <!-- Filter form -->
    <div class="flex flex-row justify-between items-center px-5">
        <app-station-filter (filterChanged)="filterChanged()"></app-station-filter>
    </div>

    <div class="flex flex-col w-100 px-5">
        
        <!-- Bulk edit and column buttons -->
        <div class="flex flex-row justify-start">
            <app-bulk-edit-button 
                [selection]="selection" 
                [entity]="bulkedit.entity" 
                [options]="bulkedit.options" 
                class="mb-3">
            </app-bulk-edit-button>
        </div>            

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Edit Station Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/station/', element.id]" mat-icon-button color="primary" aria-label="Bahnhof bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Station ID Column -->
            <ng-container matColumnDef="stationNumber">
                <th mat-header-cell *matHeaderCellDef> Bahnhofsnummer </th>
                <td mat-cell *matCellDef="let element">{{element.stationNumber}}</td>
            </ng-container>

            <!-- Station Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <!-- Station Visibility Column -->
            <ng-container matColumnDef="visibility">
                <th mat-header-cell *matHeaderCellDef> Sichtbarkeit </th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge" [ngClass]="{
                        'online':element.visibility === 'ONLINE',
                        'offline':element.visibility === 'OFFLINE',
                        'hidden':element.visibility === 'HIDDEN'
                    }">{{element.visibility | translate }}</span>
                </td>
            </ng-container>

            <!-- Station Channels Column -->
            <ng-container matColumnDef="channels">
                <th mat-header-cell *matHeaderCellDef> Ausspielkanäle </th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge channel" *ngFor="let channel of element.channels">
                        {{channel}}
                    </span>
                </td>
            </ng-container>     
            
            <!-- Station Auto Import Column -->
            <ng-container matColumnDef="isAutoImport">
                <th mat-header-cell *matHeaderCellDef> Auto-Import </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon>{{element.isParkopediaAutoImport ? 'done' : 'close'}}</mat-icon>
                </td>
            </ng-container>       
            
            <!-- Station Import Radius Column -->
            <ng-container matColumnDef="importRadius">
                <th mat-header-cell *matHeaderCellDef> Import-Radius </th>
                <td mat-cell *matCellDef="let element">{{element.parkopediaImportRadius}}</td>
            </ng-container>  

            <!-- Station VS Category Column -->
            <ng-container matColumnDef="categoryVS">
                <th mat-header-cell *matHeaderCellDef> Kategorie Verkehrsstation </th>
                <td mat-cell *matCellDef="let element">{{element.categoryVS}}</td>
            </ng-container>

            <!-- Station EVA Numbers Column -->
            <ng-container matColumnDef="evaNumbers">
                <th mat-header-cell *matHeaderCellDef> EVA Nummer(n) </th>
                <td mat-cell *matCellDef="let element">{{element.evaNumbers.join(', ')}}</td>
            </ng-container>

            <!-- Station SBN Column -->
            <ng-container matColumnDef="sbn">
                <th mat-header-cell *matHeaderCellDef> SBN </th>
                <td mat-cell *matCellDef="let element">{{element.sbn}}</td>
            </ng-container>

            <!-- Station City Column -->
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef> Stadt </th>
                <td mat-cell *matCellDef="let element">{{element.city?.name}}</td>
            </ng-container>

            <!-- Station Federal State Column -->
            <ng-container matColumnDef="federalState">
                <th mat-header-cell *matHeaderCellDef> Bundesland </th>
                <td mat-cell *matCellDef="let element">{{element.federalState}}</td>
            </ng-container>

            <!-- Station Address Column -->
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef> Adresse </th>
                <td mat-cell *matCellDef="let element">{{element.addressStreet}}<br>{{element.addressPostalCode}} {{element.addressCityName}}</td>
            </ng-container>

            <!-- Station Geolocation Column -->
            <ng-container matColumnDef="geoLocation">
                <th mat-header-cell *matHeaderCellDef> Koordinaten </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.geoLocation.longitude || element.geoLocation.latitude">
                        <a mat-icon-button color="primary" href="http://www.google.com/maps/place/{{element.geoLocation.latitude}},{{element.geoLocation.longitude}}" target="_blank" title="{{element.name}} auf Google Maps ansehen">
                            <mat-icon style="vertical-align: middle;">place</mat-icon>
                        </a>
                        {{element.geoLocation.latitude}}, {{element.geoLocation.longitude}}
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="15">Keine Daten gefunden.</td>
            </tr> 

        </table>

        <mat-paginator [length]="stationsCount" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>

</div>