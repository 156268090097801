<button *ngIf="selection.selected.length > 0" mat-flat-button color="primary" [matMenuTriggerFor]="bulkEdit" aria-label="Bulk edit">
    {{selection.selected.length}} ausgewählt <mat-icon>expand_more</mat-icon>
</button>
<mat-menu #bulkEdit="matMenu">
    <button *ngIf="options.includes('publish')" mat-menu-item (click)="openPublishDialog()">
        <mat-icon>publish</mat-icon>
        <span>Publizieren</span>
    </button>
    <button *ngIf="options.includes('visibility')" mat-menu-item (click)="openVisibilityDialog()">
        <mat-icon>visibility</mat-icon>
        <span>Sichtbarkeit</span>
    </button>
    <button *ngIf="options.includes('channels')" mat-menu-item (click)="openChannelsDialog()">
        <mat-icon>sync_alt</mat-icon>
        <span>Ausspielkanäle</span>
    </button>
    <mat-divider *ngIf="options.length > 1 && options.includes('delete')"></mat-divider>
    <button *ngIf="options.includes('delete')" mat-menu-item (click)="openDeleteDialog()">
        <mat-icon>delete</mat-icon>
        <span>Löschen</span>
    </button>
</mat-menu>