import { HttpResponse } from '@angular/common/http';
import { Directive, HostListener, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DataService } from '@core/services/data.service'; 
import { saveAs } from 'file-saver';

@Directive({
	selector: '[exportButton]'
})
export class ExportButtonDirective {

	@Input() entity: string = ''
	@Input() format: string = ''
	@Input() params: any = null

	snackOptions: MatSnackBarConfig = { duration: 3000, panelClass: 'success-snack' };

	constructor(
		private dataService: DataService,
		private snackBar: MatSnackBar
	) { }

	@HostListener('click') onClick() {

		this.dataService.downloadFile(this.entity, this.format, this.params).subscribe((response: HttpResponse<Blob>) => {

			const contentType = response.headers.get('Content-Type');
			const contentDisposition = response.headers.get('Content-Disposition')
			let fileName = '';
			
			if (contentDisposition) {
				const dispositionItems = contentDisposition.split(';');
				for (const item of dispositionItems) {
					if (item.trim().startsWith('filename=')) {
						fileName = item.split('=')[1].replace(/"/g, '');
						break;
					}
				}
			}

			if (!fileName) {
				// If filename not found in Content-Disposition, generate a default name
				fileName = `download.${contentType.split('/')[1]}`;
			}
			// Content-Type: application/octet-stream
			const blob = new Blob([response.body], { type: contentType });

			saveAs(blob, fileName);

			this.snackBar.open(`${fileName} wurde erfolgreich exportiert.`, null, this.snackOptions);
		})

	}

}
