import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BulkChannelsDialogComponent } from './bulk-channels-dialog/bulk-channels-dialog.component';
import { BulkDeleteDialogComponent } from './bulk-delete-dialog/bulk-delete-dialog.component';
import { BulkPublishDialogComponent } from './bulk-publish-dialog/bulk-publish-dialog.component';
import { BulkVisibilityDialogComponent } from './bulk-visibility-dialog/bulk-visibility-dialog.component';

@Component({
	selector: 'app-bulk-edit-button',
	templateUrl: './bulk-edit-button.component.html',
	styleUrls: ['./bulk-edit-button.component.scss']
})
export class BulkEditButtonComponent {

	@Input() selection: SelectionModel<any[]>
	@Input() options: string[]
	@Input() entity: string

	constructor(
		public dialog: MatDialog
	) { }

	openPublishDialog(): void {
		this.dialog.open(BulkPublishDialogComponent,{
            width: '40%',
			data: {
				selected: this.selection.selected,
				entity: this.entity
			}
		})
	}

	openVisibilityDialog(): void {
		this.dialog.open(BulkVisibilityDialogComponent,{
            width: '40%',
			data: {
				selected: this.selection.selected,
				entity: this.entity
			}
		})
	}

	openChannelsDialog(): void {
		this.dialog.open(BulkChannelsDialogComponent,{
            width: '40%',
			data: {
				selected: this.selection.selected,
				entity: this.entity
			}
		})
	}

	openDeleteDialog(): void {
		this.dialog.open(BulkDeleteDialogComponent, {
			width: '40%',
			data: {
				selected: this.selection.selected,
				entity: this.entity
			}
		})
	}
}
