<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">

    <mat-form-field class="search" appearance="outline" [style.margin-right.px]="16">
        <mat-label>Suche</mat-label>
        <input matInput (keyup)="onKeyup.next($event)" formControlName="name">
    </mat-form-field>

    <button class="reset-button mat-elevation-z0 mat-border" mat-raised-button aria-label="Reset all filter" matTooltip="Filter zurücksetzen" (click)="resetFilter()">
        <mat-icon>clear</mat-icon>
    </button>

</form>
