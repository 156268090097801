import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entityNames'
})
export class EntityNamesPipe implements PipeTransform {

  transform(value: string): string {

    if (value === 'cities') return 'Städte'
    if (value === 'stations') return 'Bahnhöfe'
    if (value === 'units') return 'Parkobjekte'
    if (value === 'spaces') return 'Parkräume'
    if (value === 'sites') return 'Parkbereiche'
    if (value === 'mobies') return 'Mobilitätsangebote'

    else return value
  }

}
