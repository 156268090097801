import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { DataService } from '@core/services/data.service';
import { Station } from '../station.interface';
import { Channel } from 'app/channel/channel.interface';
// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@Component({
	selector: 'app-station-add-edit',
	templateUrl: './station-add-edit.component.html',
	styleUrls: ['./station-add-edit.component.scss'],
	providers: [
		{
		  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
		  useValue: appearance
		}
	]
})
export class StationAddEditComponent implements OnInit {

	stationId: number;

	station: Station;

	isAddMode: boolean;

	channels: Channel[];

	changesDetected: boolean = false;

	snackOptions: MatSnackBarConfig = { duration: 5000, panelClass: 'success-snack' };

	form: UntypedFormGroup;

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.stationId = this.route.snapshot.params['id'];
		this.isAddMode = !this.stationId;

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			addressCityName: new UntypedFormControl(''),
			addressPostalCode: new UntypedFormControl(''),
			addressStreet: new UntypedFormControl(''),
			categoryVS: new UntypedFormControl(null),
			channels: [],
			city: this.formBuilder.group({
				id: [null],
				name: [''],
			}),
			evaNumbers: new UntypedFormControl([]),
			federalState: new UntypedFormControl(''),
			geoLocation: this.formBuilder.group({
				longitude: new UntypedFormControl(null),
				latitude: new UntypedFormControl(null),
			}),
			id: new UntypedFormControl(null),
			isParkopediaAutoImport: new UntypedFormControl(''),
			name: new UntypedFormControl('', [Validators.required]),
			parkopediaImportRadius: new UntypedFormControl(null),
			regionalDistrict: new UntypedFormControl(''),
			sbn: new UntypedFormControl(''),
			stationNumber: new UntypedFormControl(null, [Validators.required]),
			visibility: new UntypedFormControl(''),
			visibilityDescription: new UntypedFormControl(''),
		})

		/** Deprecated endpoint */
		this.dataService.getChannels().subscribe((data: Channel[]) => {
			this.channels = data.map((item: Channel) => ({
				token: item.token,
				description: item.description,
				checked: false
			}));
		});

		if (!this.isAddMode) {
			this.dataService.getStation(this.stationId).subscribe((data: Station) => {
				this.station = data;
				this.form.patchValue(data)
				// Detect form changes after values patched
				this.form.valueChanges.subscribe( () => { this.changesDetected = true })
			});
		};

	}

	addEvaNumberFromInput(event: MatChipInputEvent) {
		if (event.value) {
			this.form.get('evaNumbers').value.push(parseInt(event.value));
			event.input.value = ''; // event.chipInput!.clear(); not working
		}
	}

	removeEvaNumber(evaNumber: number) {
		this.form.get('evaNumbers').value.pop(evaNumber);
	}

	onSubmit() {
		if (this.form.invalid) return;

		if (this.isAddMode) this.addStation();
		else this.editStation();
	}

	private addStation() {
		this.dataService.postStation(this.form.value).subscribe((data: Station) => {
			this.snackBar.open('Bahnhof erfolgreich erstellt.', null, this.snackOptions);
			this.router.navigate(['/station', data.id]);
		})
	}

	private editStation() {
		this.dataService.putStation(this.stationId, this.form.value).subscribe((data: Station) => {
			this.station = data;
			this.snackBar.open('Bahnhof erfolgreich aktualisiert.', null, this.snackOptions);
		})
	}


}

