<div [formGroup]="form">
    <div formArrayName="tariffPrices">
        <div *ngFor="let tariff of tariffs.controls;let i = index;">
            <div [formGroupName]="i" class="flex flex-row">
                <mat-form-field *ngIf="isParkopedia" class="w-1/2 me-3">
                    <mat-label>Zeitpunkt</mat-label>
                    <input matInput formControlName="period">
                </mat-form-field>
                <mat-form-field class="w-full" [ngClass]="{'!w-1/2' : isParkopedia}">
                    <mat-label>{{tariff.value.duration | tariffNames }}</mat-label>
                    <input matInput formControlName="price">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>