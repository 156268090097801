<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div class="flex items-center justify-start">
            <div *ngIf="!isAddMode">
                <div class="user-status-badge" [ngClass]="user?.userStatus">{{user?.userStatus | translate}}</div> 
            </div>
            <div>
                <h1 class="mat-display-1" *ngIf="isAddMode">Neuen Benutzer erstellen</h1>
                <h1 class="mat-display-1" *ngIf="!isAddMode">Benutzer bearbeiten: <b>{{user?.username}}</b></h1>
                <span class="mat-caption" *ngIf="!isAddMode">
                    <b>ID:</b> {{userId}} |
                    <b>Erzeugt:</b> {{user?.created | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr
                </span>
            </div>
        </div>
        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm"> 
                SPEICHERN
            </button>
            <button [routerLink]="['/user']" type="button" mat-raised-button class="!ml-[16px]">
                ZURÜCK
            </button>
        </div>
    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards masonry-mat-cards-grid-3 px-5 pt-1">

            <mat-card>

                <mat-card-title>Benutzer</mat-card-title>

                <mat-card-content class="!flex flex-col">

                    <mat-form-field>
                        <mat-label>E-Mail Adresse</mat-label>
                        <input matInput formControlName="emailAddress">
                        <mat-error *ngIf="form.get('emailAddress').hasError('email')">
                            Bitte geben Sie eine valide E-Mail Adresse ein.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Benutzername</mat-label>
                        <input matInput formControlName="username" required>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Anrede</mat-label>
                        <mat-select formControlName="salutation">
                            <mat-option>Keine Anrede</mat-option>
                            <mat-option *ngFor="let option of salutationOptions" [value]="option.value">{{option.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Vorname</mat-label>
                        <input matInput formControlName="firstname">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Nachname</mat-label>
                        <input matInput formControlName="lastname">
                    </mat-form-field>

                    <mat-form-field [style.margin-bottom.px]="32">
                        <mat-label>Passwort</mat-label>
                        <input [type]="hideVisibility ? 'password' : 'text'" matInput #password formControlName="password" maxlength="72">
                        <a matSuffix mat-icon-button tabindex="-1"> 
                            <mat-icon (click)="hideVisibility = !hideVisibility" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hideVisibility">
                                {{hideVisibility ? "visibility_off" : "visibility"}}</mat-icon>
                        </a>
                        <mat-hint>8-20 Zeichen, mind. ein kleiner, ein großer Buchstabe, eine Ziffer, Name darf nicht im Passwort vorkommen. <strong>{{password.value.length}} / 72</strong></mat-hint>
                    </mat-form-field>

                </mat-card-content>

                <mat-card-actions *ngIf="!isAddMode" class="!flex flex-row justify-between items-center">
					<button *ngIf="user?.userStatus === 'ACTIVE'" 
							mat-button 
							color="primary" 
							type="button"
							(click)="changeUserStatus('LOCKED')">
						<mat-icon>person_off</mat-icon> Nutzer sperren
					</button>
					<button *ngIf="user?.userStatus !== 'ACTIVE'" 
							mat-button
							color="primary" 
							type="button"
							(click)="changeUserStatus('ACTIVE')">
						<mat-icon>person</mat-icon> Nutzer entsperren
					</button>
					<button mat-button 
							color="warn" 
							type="button"
							(click)="deleteUser()">
						<mat-icon>person_remove</mat-icon> Nutzer löschen
					</button>
				</mat-card-actions>

            </mat-card>
            
            <div>
                <mat-card>

                    <mat-card-title>Verwaltung</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Rolle</mat-label>
                            <mat-select formControlName="role" required>
                                <mat-option *ngFor="let option of roleOptions" [value]="option.value">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Channel</mat-label>
                            <mat-select formControlName="channel">
                                <mat-option [value]="null">Kein Ausspielkanal ausgewählt</mat-option>
                                <mat-option *ngFor="let option of channelOptions" [value]="option.value">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                
                    </mat-card-content>

                </mat-card>

                <mat-card *ngIf="form.get('role').value === 'DISPATCHER'" class="parkspotter-card">
                    
                    <mat-card-title>
                        Parkspotter
                        <mat-card-subtitle *ngIf="dataSource.data.length > 0" [style.margin-top.em]="0.5">
                            Dieser Dispatcher hat {{parkspotterCount}} Parkspotter Accounts.
                        </mat-card-subtitle>
                        <mat-card-subtitle *ngIf="dataSource.data.length === 0" [style.margin-top.em]="0.5">
                            Dieser Dispatcher hat keine Parkspotter Accounts.
                        </mat-card-subtitle>
                    </mat-card-title>
        
                    <mat-card-content class="!flex flex-col">
        
                        <mat-form-field>
                            <mat-label>Durchsuchen</mat-label>
                            <mat-icon matSuffix>search</mat-icon>
                            <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                        </mat-form-field>                      
        
                        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">
        
                            <!-- Edit User Column -->
                            <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
                                <td mat-cell *matCellDef="let element">
                                    <button type="button" [routerLink]="['/user/', element.id]" mat-icon-button color="primary" aria-label="Benutzer bearbeiten">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
        
                            <!-- User Status Column -->
                            <ng-container matColumnDef="userStatus">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Status </th>
                                <td mat-cell *matCellDef="let element">
                                    <code class="user-status-badge-sm" [ngClass]="element.userStatus">{{element.userStatus | translate}}</code></td>
                            </ng-container>
        
                            <!-- Username Column -->
                            <ng-container matColumnDef="username">
                                <th mat-header-cell *matHeaderCellDef> Benutzername </th>
                                <td mat-cell *matCellDef="let element">
                                    <b>{{element.username}}</b><br>
                                    <small>Erstellt am {{element.created | date: 'dd.MM.yyyy - HH:mm:ss'}}</small>
                                </td>
                            </ng-container>
        
                            <!-- Fullame Column -->
                            <ng-container matColumnDef="fullname">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.salutation | salutation}} {{element.firstname}} {{element.lastname}}<br>
                                    <small><a href="mailto:{{element.emailAddress}}">{{element.emailAddress}}</a></small>
                                </td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="dataSource.data.length > 0" class="mat-cell" colspan="4">Keine Parkspotter Accounts für "{{input.value}}" gefunden.</td>
                                <td *ngIf="dataSource.data.length === 0" class="mat-cell" colspan="4">Keine Parkspotter Accounts gefunden.</td>
                            </tr>        
        
                        </table>
                        
                        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
        
                    </mat-card-content>
        
                </mat-card>
                
                <mat-card class="parkspotter-card" *ngIf="form.get('role').value === 'PARKSPOTTER' && user?.dispatcherId">
                    <button type="button" [routerLink]="['/user/', user.dispatcherId]" mat-flat-button color="primary">DISPATCHER ANSEHEN</button>
                </mat-card>
            </div>

        </div>

    </form>

</div>