<button mat-raised-button [style.margin-right.px]="16" (click)="openImportDialog()">
    <mat-icon>upload</mat-icon> Import
</button>

<button mat-raised-button 
        [matMenuTriggerFor]="exportBtn" 
        [style.margin-right.px]="16">
    <mat-icon>download</mat-icon> Export
</button>
<mat-menu #exportBtn="matMenu">
    <button mat-menu-item exportButton 
        [entity]="entity" 
        format="CSV" 
        [params]="params">CSV</button>
    <button mat-menu-item exportButton 
        [entity]="entity" 
        format="EXCEL" 
        [params]="params">EXCEL</button>
</mat-menu>