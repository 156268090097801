import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, getActionTypeFromInstance } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { Login, Logout, Validation, AuthStateModel, Revoke } from './auth.action';
import { AuthService } from '@core/services/auth.service';
import { Navigate } from '@ngxs/router-plugin';

const authStateDefaults: AuthStateModel = {
    isLoggedIn: false
};

@State<AuthStateModel>({
    name: 'auth',
    defaults: authStateDefaults
})
@Injectable()
export class AuthState {
    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.isLoggedIn;
    }

    constructor(
        private authService: AuthService
    ) { }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this.authService.authorize(action.payload.username, action.payload.password).pipe(
            tap(
                () => {
                    ctx.patchState({
                        isLoggedIn: true
                    });
                }
            )
        );
    }

    @Action(Validation)
    validate(ctx: StateContext<AuthStateModel>) {
        return this.authService.validate(false).pipe(
            tap(
                () => {
                    ctx.patchState({
                        isLoggedIn: true
                    });
                }
            )
        );
    }

    @Action(Revoke)
    revoke(ctx: StateContext<AuthStateModel>) {
        return this.authService.revoke().pipe(
            tap(
                () => {
                    ctx.setState(authStateDefaults);
                }
            )
        );
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        return ctx.setState(authStateDefaults);
    }
}

// Meta reducer clears entire state on logout
export function logoutPlugin(state: any, action: any, next: any) {
    
    const type = getActionTypeFromInstance(action)
    
    if ( type === Logout.type || type === Revoke.type ) state = {};

    return next(state, action);
}