export interface MobiParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
    publishingStatus: string | undefined
    publishingTimestamp: string | undefined
    visibility: string | undefined
    channel: string | undefined
    mobiType: string | undefined
}

export class MobiParameterSelected {
    static readonly type = '[Mobi List] MobiParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class MobiParameterReset {
    static readonly type = '[Mobi List] MobiParameterReset';
}

