import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment';
import { MobiParameterState } from 'app/mobi/states/mobi-filter.state';
import { MobiParameterReset, MobiParameterSelected } from 'app/mobi/states/mobi-filter.action';

@Component({
	selector: 'app-mobi-filter',
	templateUrl: './mobi-filter.component.html',
	styleUrls: ['./mobi-filter.component.scss']
})
export class MobiFilterComponent implements OnInit {

	@Output() filterChanged = new EventEmitter<any>();

	form: UntypedFormGroup

	onKeyup = new Subject<KeyboardEvent>();

	publishingStatusOptions = [
		{ 'name': 'Entwurf', 'value': 'DRAFT' },
		{ 'name': 'Publiziert', 'value': 'PUBLISHED' },
		{ 'name': 'Publiziert am...', 'value': 'ARCHIVED' }
	]

	visibilityOptions = [
		{ 'name': 'Online', 'value': 'ONLINE' },
		{ 'name': 'Offline', 'value': 'OFFLINE' },
		{ 'name': 'Prüfen', 'value': 'PENDING' },
		{ 'name': 'Gelöscht', 'value': 'DELETED' },
		{ 'name': 'Versteckt', 'value': 'HIDDEN' }
	];

	channelOptions = [
		{ 'name': 'DB BahnPark', 'value': 'DBBP' },
		{ 'name': 'Parken am Bahnhof', 'value': 'PAB' },
		{ 'name': 'DataHub', 'value': 'DATAHUB' },
		{ 'name': 'OpenData', 'value': 'OPENDATA' },
		{ 'name': 'PDF', 'value': 'PDF' },
		{ 'name': 'PIT', 'value': 'PIT' },
		{ 'name': 'bahn.de', 'value': 'BAHN' },
		{ 'name': 'bahnhof.de', 'value': 'BAHNHOF' },
		{ 'name': 'Kundenfeedback', 'value': 'FEEDBACK' },
		// {'name': 'Dashboard', 'value': 'DASHBOARD'}
	];
	
	mobiTypeOptions = [
		{ 'name': 'Fahrradbox', 'value': 'BIKE_BOX' },
		{ 'name': 'Stellplatz für Lastenräder', 'value': 'BIKE_CARGO' },
		{ 'name': 'Fahrradparkhaus oder -sammelanlage', 'value': 'BIKE_GARAGE' },
		{ 'name': 'Fahrradabstellanlage', 'value': 'BIKE_PARKING' },
		{ 'name': 'Leihfahrräder', 'value': 'BIKE_RENTAL' },
		{ 'name': 'Fahrradservicestation', 'value': 'BIKE_SERVICE' },
		{ 'name': 'Autovermietung', 'value': 'CAR_RENTAL' },
		{ 'name': 'Reservierung/Vorverkauf PKW-Stellplatz', 'value': 'CAR_PARKING_SERVICE' },
		{ 'name': 'Carsharing', 'value': 'CAR_SHARING' },
		{ 'name': 'E-Laden PKW', 'value': 'E_CAR_CHARGING' },
		{ 'name': 'E-Laden Fahrrad', 'value': 'E_BIKE_CHARGING' },
		{ 'name': 'E-Roller', 'value': 'E_ROLLER' },
		{ 'name': 'E-Tretroller', 'value': 'E_SCOOTER' },
		{ 'name': 'Kiss&Ride', 'value': 'KISS_AND_RIDE' },
		{ 'name': 'Ridehailing/Ruftaxi', 'value': 'RIDE_HAILING' },
		{ 'name': 'Ridepooling/Ruf-Sammeltaxi', 'value': 'RIDE_POOLING' },
		{ 'name': 'Paketstation', 'value': 'PARCEL_STATION' }
	];


	constructor(
		private formBuilder: UntypedFormBuilder,
		private store: Store
	) { }

	ngOnInit(): void {

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			name: new UntypedFormControl(''),
			publishingStatus: new UntypedFormControl(''),
			publishingTimestamp: new UntypedFormControl(''),
			visibility: new UntypedFormControl(''),
			channel: new UntypedFormControl(''),
			mobiType: new UntypedFormControl(''),
		})

		this.form.patchValue(
			this.store.selectSnapshot(MobiParameterState.getCurrentParams)
		)

		this.onKeyup.pipe(
			map((event: any) => event.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(() => {
			this.onSelect()
		});

	}

	onSelect(event: any = '') {
		if (this.form.get('publishingStatus').value === 'ARCHIVED') {
			if (this.form.get('publishingTimestamp').value) {
				const publishingTimestamp = moment(event.value).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
				this.form.get('publishingTimestamp').patchValue(publishingTimestamp);
				this.filterChanged.emit()
				this.store.dispatch(new MobiParameterSelected(this.form.value))
			}
		} else {
			// Reset "publishingTimestamp"
			this.form.get('publishingTimestamp').patchValue(null);
			this.filterChanged.emit()
			this.store.dispatch(new MobiParameterSelected(this.form.value))
		}
	}

	resetFilter() {
		this.form.reset();
		this.store.dispatch(new MobiParameterReset())
	}

}
