<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div class="flex-item">
            <h1 class="mat-display-1">{{usersCount}} Benutzer</h1>
        </div>
        <div class="flex-item">
            <button [routerLink]="['/user/add']" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Erstellen
            </button>
        </div>
    </div>

    <!-- Filter form -->
    <div class="flex flex-row justify-between items-center px-5">
        <app-user-filter (filterChanged)="filterChanged()"></app-user-filter>
    </div>

    <div class="flex flex-col w-100 px-5">

        <!-- Bulk edit and column buttons 
        <div class="flex flex-row justify-start">
            <app-bulk-edit-button 
                [selection]="selection" 
                [entity]="bulkedit.entity" 
                [options]="bulkedit.options" 
                class="mb-4">
            </app-bulk-edit-button>
        </div> -->

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Edit User Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/user/', element.id]"
                            mat-icon-button color="primary" 
                            aria-label="Benutzer bearbeiten" 
                            [disabled]="element.role === 'API'" 
                            matTooltip="API Benutzer können unter API Keys bearbeitet werden."
                            matTooltipClass="tooltip-warning">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- User ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"><code>{{element.id}}</code></td>
            </ng-container>

            <!-- User Status Column -->
            <ng-container matColumnDef="userStatus">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <code class="user-status-badge" [ngClass]="element.userStatus">{{element.userStatus | translate}}</code></td>
            </ng-container>

            <!-- User Role Column -->
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Rolle </th>
                <td mat-cell *matCellDef="let element">{{element.role}}</td>
            </ng-container>

            <!-- User Username Column -->
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> Benutzername </th>
                <td mat-cell *matCellDef="let element">{{element.username}}</td>
            </ng-container>

            <!-- User Fullname Column -->
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">{{element.salutation | salutation}} {{element.firstname}} {{element.lastname}}</td>
            </ng-container>

            <!-- User Channel Column -->
            <ng-container matColumnDef="channel">
                <th mat-header-cell *matHeaderCellDef> Ausspielkanal </th>
                <td mat-cell *matCellDef="let element">{{element.channel}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11">Keine Daten für "{{dataSource.filter}}" gefunden.</td>
            </tr> 

        </table>

        <mat-paginator [length]="usersCount" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>

</div>