import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { Store } from '@ngxs/store';

import { AuthState } from '@core/states/auth/auth.state';
import { UserState } from '@core/states/user/user.state';
import { MeDialogComponent } from './me-dialog/me-dialog.component';
import { Me } from './me-dialog/me.interface';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public me$: Observable<Me>;

    public isAuthenticated$: Observable<boolean>;
    private allowedRoles: string[] = ['ADMIN', 'MANAGER'];

    destroy: Subject<boolean> = new Subject<boolean>();
    
    constructor(
        private store: Store,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
        this.me$ = this.store.select(UserState)
    }

    hasPermission(role: string): boolean {
        if ( !role ) return false
        return this.allowedRoles.includes(role)
    }

    openMeDialog() {
        this.dialog.open(MeDialogComponent, { 
            data: this.me$,
            autoFocus: false
        });
    }


}
