import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Role } from '@core/enums/role.enum';
import { Logout, Revoke } from '@core/states/auth/auth.action';
import { UserState } from '@core/states/user/user.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {

	private allowedRules: string[] = [
		Role.ADMIN,
		Role.MANAGER,
		Role.USER,
		Role.ORANGE,
		Role.MOBI
	]

	constructor(
        private store: Store,
        private router: Router
    ) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		  
		const userRole = this.store.selectSnapshot(UserState.getCurrentUserRole);

		if ( !this.allowedRules.includes(userRole) ) {

			this.store.dispatch([new Logout, new Revoke])
			
			this.router.navigate(['/'], { queryParams: { redirect_to: state.url }})
            return false;
		}
		
		return true;
	}

}
