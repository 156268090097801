import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { InterceptorSkipHeader } from '@core/interceptors/http-error.interceptor';

import { City } from 'app/city/city.interface';
import { Station } from 'app/station/station.interface';
import { Unit } from 'app/unit/unit.interface';
import { Space } from 'app/space/space.interface';
import { Site } from 'app/site/site.interface';
import { Channel } from 'app/channel/channel.interface';
import { User } from 'app/user/user.interface';
import { Me } from '@core/layout/header/me-dialog/me.interface';
import { Mobi } from 'app/mobi/mobi.interface';


@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private http: HttpClient
    ) { }

    getApiVersion(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/version`)
    }

    /**
     * city endpoints
     */

    getCities(params: any): Observable<City[]> {
        return this.http.get<City[]>(`${environment.apiUrl}/v2/cities`, {params: params})
    }

    getCity(id: number): Observable<City> {
        return this.http.get<City>(`${environment.apiUrl}/v2/cities/${id}`)
    }

    postCity(city: City): Observable<City> {
        return this.http.post<City>(`${environment.apiUrl}/v2/cities`, city)
    }

    putCity(id: number, city: City): Observable<City> {
        return this.http.put<City>(`${environment.apiUrl}/v2/cities/${id}`, city)
    }

    deleteCity(id: number, interceptor: boolean = true): Observable<City> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<City>(`${environment.apiUrl}/v2/cities/${id}`, { headers })
    }

    /**
     * user-controller
     */

    getMe(): Observable<Me> {
        return this.http.get<Me>(`${environment.apiUrl}/v2/users/me`)
    }

    putMePassword(passwords: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/v2/users/me/password`, passwords)
    }

    getUsers(params: any): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/v2/users/`, {params: params})
    }

    getUser(id: number): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/v2/users/${id}`)
    }

    postUser(user: User): Observable<User> {
        return this.http.post<User>(`${environment.apiUrl}/v2/users/`, user)
    }

    putUser(id: number, user: User): Observable<User> {
        return this.http.put<User>(`${environment.apiUrl}/v2/users/${id}`, user)
    }

    deleteUser(id: number, interceptor: boolean = true): Observable<User> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<User>(`${environment.apiUrl}/v2/users/${id}`, { headers })
    }

    getUserSpaceAssignments(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v2/users/${id}/spaces/assignments`);
    }

    addUserSpaceAssignments(id: number, spaceIds: number[]): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/v2/users/${id}/spaces/assignments`, spaceIds);
    }

    removeUserSpaceAssignments(id: number, spaceIds: number[]): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/v2/users/${id}/spaces/assignments/delete`, spaceIds);
    }

    removeAllUserSpaceAssignments(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/v2/users/${id}/spaces/assignments/delete/all`);
    }


    /**
     * apikey-controller
     */
    getApiKeys(userId: number): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/v2/users/${userId}/apikeys`)
    }

    createApiKey(userId: number, expirationDate: string | null): Observable<User> {
        let params = expirationDate === null ? {} : new HttpParams().set('expirationDate', expirationDate)
        return this.http.post<User>(`${environment.apiUrl}/v2/users/${userId}/apikeys`, params)
    }

    updateApiKey(userId: number, keyId: number, expirationDate: string | null): Observable<User> {
        let params = expirationDate === null ? {} : new HttpParams().set('expirationDate', expirationDate)
        return this.http.put<User>(`${environment.apiUrl}/v2/users/${userId}/apikeys/${keyId}`, params)
    }

    deleteApiKey(userId: number, keyId: number): Observable<User> {
        return this.http.delete<User>(`${environment.apiUrl}/v2/users/${userId}/apikeys/${keyId}`)
    }


    /**
     * channel-controller
     */

    getChannels(): Observable<Channel[]> {
        return this.http.get<Channel[]>(`${environment.apiUrl}/v2/channels`)
    }

    getChannel(id: number): Observable<Channel> {
        return this.http.get<Channel>(`${environment.apiUrl}/v2/channels/${id}`)
    }

    postChannel(channel: Channel): Observable<Channel> {
        return this.http.post<Channel>(`${environment.apiUrl}/v2/channels`, channel)
    }

    putChannel(id: number, channel: Channel): Observable<Channel> {
        return this.http.post<Channel>(`${environment.apiUrl}/v2/channels/${id}`, channel)
    }

    deleteChannel(id: number, interceptor: boolean = true): Observable<Space> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<Space>(`${environment.apiUrl}/v2/spaces/${id}`, { headers })
    }



    /**
     * station-controller
     */

    getStations(params: any): Observable<Station[]> {
        return this.http.get<Station[]>(`${environment.apiUrl}/v2/stations`, {params: params})
    }

    getStation(id: number): Observable<Station> {
        return this.http.get<Station>(`${environment.apiUrl}/v2/stations/${id}`)
    }

    postStation(station: Station): Observable<Station> {
        return this.http.post<Station>(`${environment.apiUrl}/v2/stations`, station)
    }

    putStation(id: number, station: Station): Observable<Station> {
        return this.http.put<Station>(`${environment.apiUrl}/v2/stations/${id}`, station)
    }

    deleteStation(id: number, interceptor: boolean = true): Observable<Station> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<Station>(`${environment.apiUrl}/v2/stations/${id}`, { headers })
    }

    /**
     * unit-controller
     */

    getUnits(params: any): Observable<Unit[]> {
        return this.http.get<Unit[]>(`${environment.apiUrl}/v2/units`, {params: params})
    }

    getUnit(id: number): Observable<Unit> {
        return this.http.get<Unit>(`${environment.apiUrl}/v2/units/${id}`)
    }

    postUnit(unit: Unit): Observable<Unit> {
        return this.http.post<Unit>(`${environment.apiUrl}/v2/units`, unit)
    }

    putUnit(id: number, unit: Unit): Observable<Unit> {
        return this.http.put<Unit>(`${environment.apiUrl}/v2/units/${id}`, unit)
    }

    deleteUnit(id: number, interceptor: boolean = true): Observable<Unit> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<Unit>(`${environment.apiUrl}/v2/untis/${id}`, { headers })
    }

    /**
     * space-controller
     */

    getSpaces(params: any): Observable<Space[]> {
        return this.http.get<Space[]>(`${environment.apiUrl}/v2/spaces`, {params: params})
    }

    getSpaceById(id: number): Observable<Space[]> {
        return this.http.get<Space[]>(`${environment.apiUrl}/v2/spaces/${id}`)
    }

    getSpace(id :number, version: number, interceptor: boolean = true): Observable<Space> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Space>(`${environment.apiUrl}/v2/spaces/${id}/${version}`, { headers })
    }

    postSpace(space: Space): Observable<Space> {
        return this.http.post<Space>(`${environment.apiUrl}/v2/spaces`, space)
    }

    putSpace(id: number, space: any, interceptor: boolean = true): Observable<Space> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.put<Space>(`${environment.apiUrl}/v2/spaces/${id}`, space, { headers })
    }

    publishSpace(id: number, version: number, interceptor: boolean = true): Observable<Space> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.put<Space>(`${environment.apiUrl}/v2/spaces/${id}/${version}/publish`, {}, { headers })
    }

    deleteSpace(id: number, version: number, interceptor: boolean = true): Observable<Space> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<Space>(`${environment.apiUrl}/v2/spaces/${id}/${version}`, { headers })
    }


    /**
     * site-controller
     */

    getSites(params: any): Observable<Site[]> {
        return this.http.get<Site[]>(`${environment.apiUrl}/v2/sites`, {params: params})
    }

    getSite(id :number): Observable<Site> {
        return this.http.get<Site>(`${environment.apiUrl}/v2/sites/${id}`)
    }

    postSite(site: Site): Observable<Site> {
        return this.http.post<Site>(`${environment.apiUrl}/v2/sites`, site)
    }

    putSite(id :number, site: Site): Observable<Site> {
        return this.http.put<Site>(`${environment.apiUrl}/v2/sites/${id}`, site)
    }

    deleteSite(id: number, interceptor: boolean = true): Observable<Site> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<Site>(`${environment.apiUrl}/v2/sites/${id}`, { headers })
    }

    
    /**
     * mobi-controller
     */

    getMobies(params: any): Observable<Mobi[]> {
        return this.http.get<Mobi[]>(`${environment.apiUrl}/v2/mobies`, {params: params})
    }

    getMobiById(id: number): Observable<Mobi[]> {
        return this.http.get<Mobi[]>(`${environment.apiUrl}/v2/mobies/${id}`)
    }

    getMobi(id :number, version: number, interceptor: boolean = true): Observable<Mobi> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Mobi>(`${environment.apiUrl}/v2/mobies/${id}/${version}`, { headers })
    }

    postMobi(mobi: Mobi): Observable<Mobi> {
        return this.http.post<Mobi>(`${environment.apiUrl}/v2/mobies`, mobi)
    }

    putMobi(id: number, mobi: any, interceptor: boolean = true): Observable<Mobi> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.put<Mobi>(`${environment.apiUrl}/v2/mobies/${id}`, mobi, { headers })
    }

    publishMobi(id: number, version: number, interceptor: boolean = true): Observable<Mobi> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.put<Mobi>(`${environment.apiUrl}/v2/mobies/${id}/${version}/publish`, {}, { headers })
    }

    deleteMobi(id: number, version: number, interceptor: boolean = true): Observable<Mobi> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<Mobi>(`${environment.apiUrl}/v2/mobies/${id}/${version}`, { headers })
    }

    
    /**
     * Import and Export Files
     */

    downloadFile(entity: string, format: string, params: any): Observable<HttpResponse<Blob>> {
		return this.http.get<Blob>(`${environment.apiUrl}/v2/${entity}/export/${format}`,  {
			observe: 'response',
			responseType: 'blob' as 'json',
			params: params
		})
	}

	uploadFile(entity: string, formData: FormData): Observable<any> {
		return this.http.post(`${environment.apiUrl}/v2/${entity}/import/excel`, formData)
	}

    /*

    API Service
       
    fetch(
        entity: string, 
        id: number, 
        params: any,
        version: number, 
        interceptor: boolean = true
    ): Observable<any> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<any>(`${environment.apiUrl}/v2/${entity}/${id ? `${id}` : ``}`, { 
            headers, 
            params: params 
        })
    }

    post(
        entity: string, 
        payload: any = []
    ): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/v2/${entity}`, payload)
    }

    put(
        entity: string, 
        id: number, 
        payload: any, 
        interceptor: boolean = true
    ): Observable<any> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.put<any>(`${environment.apiUrl}/v2/${entity}/${id}`, payload, { headers })
    }

    delete(
        entity: string, 
        id: number, 
        interceptor: boolean = true
    ): Observable<any> {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.delete<any>(`${environment.apiUrl}/v2/${entity}/${id}`, { headers })
    }

    */


}
