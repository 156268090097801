import { Component, OnInit } from '@angular/core';
import { UserStateModel } from '@core/states/user/user.action';
import { Store } from '@ngxs/store';
import { UserState } from '@core/states/user/user.state';
import { environment } from '@environments/environment';
import { DataService } from '@core/services/data.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public currentUser: UserStateModel | undefined;
    api: any
    appVersion: string = environment.appVersion

    constructor(
        private store: Store,
        private dataService: DataService
    ) { }

    ngOnInit(): void {
        this.currentUser = this.store.selectSnapshot(UserState.getCurrentUser);
        this.dataService.getApiVersion().subscribe((data: any) => {
            this.api = data
        })
    }

}
