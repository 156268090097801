import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { Login, Validation } from '@core/states/auth/auth.action';
import { SetUser } from '@core/states/user/user.action';
import { switchMap } from 'rxjs/operators';


/**
 * @title Login component
 * 
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public userInputValue: string = '';
    public passwordInputValue: string = '';
    public hideVisibility: boolean = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private store: Store
    ) { }

    ngOnInit(): void {
        this.validation();
    }

    redirectTo(): string {
        return this.route.snapshot.queryParams['redirect_to'] || '/home';
    }

    validation(): void {
        this.store.dispatch(new Validation())
        .pipe(
            switchMap(() => this.store.dispatch(new SetUser())
        ))
        .subscribe(
            () => {
                this.router.navigate([this.redirectTo()])
            }
        )  
    }

    login(): void {
        this.snackBar.open('Anmelden...', '', { duration: 2000, panelClass: 'info-snack' });
        
        this.store.dispatch(new Login({
            username: this.userInputValue,
            password: this.passwordInputValue
        }))
        .pipe(
            switchMap(() => this.store.dispatch(new SetUser())
        ))
        .subscribe(
            () => {
                this.snackBar.open('Authentifizierung erfolgreich. Willkommen!', '🛰️', { duration: 2000, panelClass: 'success-snack' });
                this.router.navigate([this.redirectTo()])
            }
        )
    }

}