export interface CityParametersModel {
    size: number | undefined
    page: number | undefined
    name: string | undefined
}

export class CityParameterSelected {
    static readonly type = '[City List] CityParameterSelected';
    constructor(
        public urlParams: any
    ) {}   
}

export class CityParameterReset {
    static readonly type = '[City List] CityParameterReset';
}

