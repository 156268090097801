import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-datepicker-dialog',
	templateUrl: './datepicker-dialog.component.html',
	styleUrls: ['./datepicker-dialog.component.scss']
})
export class DatepickerDialogComponent implements OnInit {

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: string,
    	private dialogRef: MatDialogRef<DatepickerDialogComponent>
	) { }

	expirationDate: Date | null;

	ngOnInit(): void {
	}

	continue(): void {
		this.dialogRef.close({ data: this.expirationDate || null })
	}

}
