<mat-form-field class="!flex flex-col" formGroupName="unit">
    <mat-label>Parkobjekt</mat-label>
    <input type="text" placeholder="Parkobjekt suchen..." aria-label="Parkobjekt" matInput 
        formControlName="name" 
        [matAutocomplete]="autoUnit"
        (keyup)="onKeyup()"
        [required]="isRequired">
    <mat-autocomplete #autoUnit="matAutocomplete">
        <mat-option *ngFor="let u of filteredUnits | async" [value]="u.name" (onSelectionChange)="selectionChange(u.id)">
            {{u.name}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="isRequired && unit.get('name').hasError('required')">
        Bitte wählen Sie ein Parkobjekt aus der Liste
    </mat-error>
</mat-form-field>

<!--
{{unit.value | json}}
{{isRequired}}
 -->