import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { User } from 'app/user/user.interface';
import { DatepickerDialogComponent } from './datepicker-dialog/datepicker-dialog.component';

// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@Component({
	selector: 'app-apikey-add-edit',
	templateUrl: './apikey-add-edit.component.html',
	styleUrls: ['./apikey-add-edit.component.scss'],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	]
})
export class ApikeyAddEditComponent implements OnInit {

	@Input() dateSelected: any

	userId: number;
	user: User;
	isAddMode: boolean;

	assignmentsText: string = `Wenn sie die API Keys auf einen oder mehrere 
	Parkräume beschränken möchten, müssen Sie zunächst den Auspielkanal entfernen 
	und/oder auf "SPEICHERN" klicken.`;

	canDoAssignments: boolean;

	snackOptions: any = { duration: 5000, panelClass: 'success-snack' };

	hideVisibility: boolean = true;

	apiKeys: any; // TODO: Apikey interface
	panelOpenState: boolean;

	form: UntypedFormGroup;

	roleOptions = [
		{ 'name': 'API', 'value': 'API' }
	];

	/*statusOptions = [
		{ 'name': 'Aktiv', 'value': 'ACTIVE' },
		{ 'name': 'Blockiert', 'value': 'BLOCKED' },
		{ 'name': 'Gesperrt', 'value': 'LOCKED' },
		{ 'name': 'Eingeladen', 'value': 'PENDING' },
		{ 'name': 'Gelöscht', 'value': 'DELETED' },
	];*/

	apiOptions = [
		{ 'name': 'Web', 'value': 'WEB' },
		{ 'name': 'DataHub', 'value': 'DATAHUB' },
		{ 'name': 'OpenData', 'value': 'OPENDATA' },
		{ 'name': 'OpenData Extended', 'value': 'OPENDATA_EXT' }
	];

	channelOptions = [
		{ 'name': 'DB BahnPark', 'value': 'DBBP' },
		{ 'name': 'Parken am Bahnhof', 'value': 'PAB' },
		{ 'name': 'DataHub', 'value': 'DATAHUB' },
		{ 'name': 'OpenData', 'value': 'OPENDATA' },
		{ 'name': 'PDF', 'value': 'PDF' },
		{ 'name': 'PIT', 'value': 'PIT' },
		{ 'name': 'bahn.de', 'value': 'BAHN' },
		{ 'name': 'bahnhof.de', 'value': 'BAHNHOF' },
		{ 'name': 'Kundenfeedback', 'value': 'FEEDBACK' },
		// { 'name': 'Dashboard', 'value': 'DASHBOARD' }
	];

	salutationOptions = [
		{ 'name': 'Frau', 'value': 'MS' },
		{ 'name': 'Herr', 'value': 'MR' },
		{ 'name': 'Divers', 'value': 'DIVERSE' },
		{ 'name': 'Keine', 'value': 'NONE' },
	]

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private datePipe: DatePipe
	) { }

	ngOnInit(): void {

		this.userId = this.route.snapshot.params['id'];
		this.isAddMode = !this.userId;

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			api: new UntypedFormControl('', [Validators.required]),
			role: new UntypedFormControl('API', [Validators.required]),
			userStatus: new UntypedFormControl('ACTIVE'),
			channel: new UntypedFormControl(null),
			emailAddress: new UntypedFormControl('', [Validators.email]),
			username: new UntypedFormControl('', [Validators.required]),
			salutation: new UntypedFormControl(null),
			firstname: new UntypedFormControl(''),
			lastname: new UntypedFormControl('')
		})

		if (!this.isAddMode) {

			this.dataService.getUser(this.userId).subscribe((data: any) => {
				this.user = data;
				if ( ['OPENDATA', 'OPENDATA_EXT'].includes(this.user.api) && this.user.channel === null ) this.canDoAssignments = true;
				this.form.patchValue(data)
			});

			this.dataService.getApiKeys(this.userId).subscribe((data: any) => {
				this.apiKeys = data;
			})
		};

	}

	onApiChange(event: string) {
		if ( ['OPENDATA', 'OPENDATA_EXT'].includes(event) ) {
			this.canDoAssignments = false;
		}
	}

	onSubmit() {
		if (this.form.invalid) return;

		if (this.isAddMode) this.addUser();
		else this.editUser();
	}

	private addUser() {
		this.dataService.postUser(this.form.value).subscribe((data: any) => {
			this.router.navigate(['/apikey', data.id]);
			this.snackBar.open('Benutzer erfolgreich erstellt.', null, this.snackOptions);
		})
	}

	private editUser() {
		this.dataService.putUser(this.userId, this.form.value).subscribe((data: any) => {
			this.user = data;
			if ( ['OPENDATA', 'OPENDATA_EXT'].includes(this.user.api) && this.user.channel === null ) this.canDoAssignments = true;
			this.snackBar.open('Benutzer erfolgreich aktualisiert.', null, this.snackOptions);
		})
	}

	createApikey(userId: number) {
		let dialogRef = this.dialog.open(DatepickerDialogComponent, { 
			width: '25vw',
			maxHeight: '90vh',
            autoFocus: true
        });

		dialogRef.afterClosed().subscribe(res => {
			if ( res.data || res.data === null ) {
				const expirationDate = this.datePipe.transform(res.data, 'yyyy-MM-dd');
				this.dataService.createApiKey(userId, expirationDate).subscribe((data: any) => {
					data.panelOpenState = true;
					this.apiKeys.push(data);
				})
			}
		});

	}

	renewApikey(userId: number, keyId: number) {
		let dialogRef = this.dialog.open(DatepickerDialogComponent, { 
			width: '25vw',
			maxHeight: '90vh',
            autoFocus: true
        });

		dialogRef.afterClosed().subscribe(res => {
			if ( res.data || res.data === null ) { // res.data !== ''
				const expirationDate = this.datePipe.transform(res.data, 'yyyy-MM-dd');
				this.dataService.updateApiKey(userId, keyId, expirationDate).subscribe((data: any) => {
					data.panelOpenState = true;
					this.apiKeys = this.apiKeys.map((a: any) => [data].find((b: any) => b.id === a.id) || a)
				});
			}
		});
	}

	removeApikey(userId: number, keyId: number) {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `API Key endgültig löschen?`,
				text: `Sind Sie absolut sicher, dass Sie diesen API Key löschen wollen? Wenn ja, tippen Sie <code>LÖSCHEN</code> in Großbuchstaben in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN.`,
				confirmation: `LÖSCHEN`
			}
        });

		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.dataService.deleteApiKey(userId, keyId).subscribe(() => {
					this.apiKeys = this.apiKeys.filter((item: any) => item.id !== keyId)
					this.snackBar.open('API Key erfolgreich gelöscht.', null, this.snackOptions);
				});
			}	
		});
	}

	isDateExpired(expirationDate: string | null): boolean {
		if ( !expirationDate ) return false
		const today = new Date()
		const date = new Date(expirationDate)
		today.setHours(0, 0, 0, 0)
		return  date < today
	}

	changeUserStatus(status: string): void {
		this.form.get('userStatus').setValue(status);
		this.editUser();
	}

	deleteUser(): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `${this.user.username}</b> endgültig löschen?`,
				text: `Wenn Sie diesen Benutzer löschen, gehen auch alle dazugehörigen API Keys verloren. Sind Sie damit einverstanden? Wenn ja, tippen Sie <code>LÖSCHEN</code> in Großbuchstaben in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN.`,
				confirmation: `LÖSCHEN`
			}
        });

		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.dataService.deleteUser(this.userId).subscribe(() => {
					this.snackBar.open('Benutzer erfolgreich gelöscht.', null, this.snackOptions);
					this.router.navigate(['/apikey']);
				})
			}	
		});
	}
}
