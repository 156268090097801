import { CurrencyPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { Channel } from 'app/channel/channel.interface';
import { Subscription, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Space } from '../space.interface';
import { TinymceConfig } from './tinymce.config'
import { Tariff } from '../tariff.interface';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

// Set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@Component({
	selector: 'app-space-add-edit',
	templateUrl: './space-add-edit.component.html',
	styleUrls: ['./space-add-edit.component.scss'],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	]
})
export class SpaceAddEditComponent implements OnInit, AfterViewChecked {

	// Current space id from param
	spaceId: number;
	// Current space object
	space: Space;
	// Snapshot of current patched form value
	patchedFormValue: Space;

	isAddMode: boolean;

	hasPrev: boolean = false;
	hasNext: boolean = false;

	channels: Channel[];

	snackOptions: MatSnackBarConfig = { duration: 5000, panelClass: 'success-snack' };

	tinymceConfig: any = TinymceConfig.settings

	form: UntypedFormGroup;

	@ViewChild(MatAccordion) accordion: MatAccordion;

	constructor(
		private dataService: DataService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private currency: CurrencyPipe,
		private readonly changeDetectorRef: ChangeDetectorRef
	) { }

	// A better solution would be calling detectChanges() just when its needed (on validation)
	// but for now its working fine...
	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	ngOnInit(): void {
		// Get id parameter from route
		this.spaceId = this.route.snapshot.params['id'];
		// Add or edit mode
		this.isAddMode = !this.spaceId;
		// Get channels
		this.getChannels();
		// Build the formular
		this.buildForm();
		// If edit mode patch space values
		if (!this.isAddMode) {
			// Get current version param
			const version = this.route.snapshot.params['version'];
			// Get space from data service
			this.loadSpaceData(version).then((data: any) => {
				// Current version data
				this.space = data[1];
				// Build the tariff prices form array
				this.buildTariffFormArray(this.space);
				// Patch current version data
				this.patchSpaceValues();
				// Check if previous and next version are available
				this.hasNext = data[2] ? true : false;
				this.hasPrev = data[0] ? true : false;
			})
		} else {
			// If add mode just build the DB BahnPark tariff form array
			this.buildTariffFormArray(null);
		}
		
	}

	onParkspottingChange(event: MatSlideToggleChange): void {
		if ( event.checked ) {
			this.addParkspottingControls()
		} else {
			this.removeParkspottingControls()
		}
	}

	addChipValue(event: MatChipInputEvent, fcName: string): void {
		if (event.value) {
			this.form.get(fcName).value.push(parseInt(event.value));
			event.input.value = ''; // event.chipInput!.clear(); not working
			this.form.get(fcName).markAsTouched();
			this.form.get(fcName).updateValueAndValidity();
		}
	}

	removeChipValue(value: any, fcName: string): void {
		this.form.get(fcName).value.pop(value);
	}

	addParkspottingControls(): void {

		const seasonParkerReservations = this.space?.seasonParkerReservations || null;
		const occupancySeasonParker = this.space?.occupancySeasonParker || null;
		const seasonParkerVss = this.space?.seasonParkerVss || null;
		const psaIds = this.space?.psaIds || [];
		const psAppId = this.space?.psAppId || null;
		const specialAgreements = this.space?.specialAgreements || false;

		this.form.addControl('seasonParkerReservations', this.formBuilder.control(seasonParkerReservations, Validators.required));
		this.form.addControl('occupancySeasonParker', this.formBuilder.control(occupancySeasonParker, Validators.required));
		this.form.addControl('seasonParkerVss', this.formBuilder.control(seasonParkerVss, Validators.required));
		this.form.addControl('psaIds', this.formBuilder.control(psaIds, Validators.required));
		this.form.addControl('psAppId', this.formBuilder.control(psAppId));
		this.form.addControl('specialAgreements', this.formBuilder.control(specialAgreements));
	}

	removeParkspottingControls(): void { 
		this.form.removeControl('seasonParkerReservations');
		this.form.removeControl('occupancySeasonParker');
		this.form.removeControl('seasonParkerVss');
		this.form.removeControl('psaIds');
		this.form.removeControl('psAppId');
		this.form.removeControl('specialAgreements');
	}


	/** Tariff prices form array getter */ 
	get tariffs(): UntypedFormArray { 
		return this.form.get('tariffPrices') as UntypedFormArray; 
	}
	
	/** Build the tariff form array */
	buildTariffFormArray(space: Space | null): void {

		const defaultDurations: Tariff[] = [
			{ duration: '20min' },
			{ duration: '30min' },
			{ duration: '1hour' },
			{ duration: '1day' },
			{ duration: '1dayPCard' },
			{ duration: '1week' },
			{ duration: '1weekPCard' },
			{ duration: '1monthLongTerm' },
			{ duration: '1monthReservation' },
			{ duration: '1monthVendingMachine' }
		];
	
		const tariffsData = space ? space.tariffPrices : defaultDurations;
	
		tariffsData.forEach(value => {
			this.tariffs.push(this.formBuilder.group({
				arg: [value.arg],
				duration: [value.duration],
				groupLabel: [value.groupLabel],
				groupName: [value.groupName],
				id: [value.id],
				period: [value.period],
				price: [value.price],
			}));
		});

	}


	/** HTTP request of space by ID */
	loadSpaceData(currentVersion: any): Promise<Space[]> {
		// Convert into number
		const version: number = parseInt(currentVersion)
		// Get space data from all 3 versions, skip http error interceptor
		const versions: number[] = [
			version - 1,
			version,
			version + 1
		];
		
		return forkJoin([
			this.dataService.getSpace(this.spaceId, versions[0], false).pipe(catchError(() => of(null))),
			this.dataService.getSpace(this.spaceId, versions[1], false).pipe(catchError(() => of(null))),
			this.dataService.getSpace(this.spaceId, versions[2], false).pipe(catchError(() => of(null)))
		]).toPromise();
	}

	/** Get channel list via http request */
	getChannels(): void {
		this.dataService.getChannels().subscribe((data: Channel[]) => {
			this.channels = data.map((item: Channel) => ({
				token: item.token,
				description: item.description,
				checked: false
			}));
		});
	}

	/** Patch space data into form */
	patchSpaceValues(): void {
		// Convert into displayed values before patching
		this.space = this.convertDisplayedValues(this.space);
		// Reset entire form first
		this.form.reset();
		// Add parkspotting controls
		if ( this.space.isParkspotting ) {
			this.addParkspottingControls();
		}
		// Patch with formatted space data
		this.form.patchValue(this.space, { emitEvent: false });
		// Get patched space data (raw because parkopedia form is mostly disabled)
		this.patchedFormValue = this.form.getRawValue(); // value;
		// If responsibility is foreign, disable most of the form
		if (this.space.responsibility === 'FOREIGN') {
			this.form.disable();
			this.form.get('visibility').enable();
			this.form.get('visibilityDescription').enable();
			this.form.get('channels').enable();
			this.form.get('responsibility').enable();
		}
	}

	/** Space version navigator */
	navigateToVersion(version: number): void {
		// Navigate to desired version then patch new data to formular
		this.router.navigate(['/space/', this.spaceId, version]).then(() => {
			this.loadSpaceData(version).then((data: any) => {
				// Check if a version before and after exists
				this.hasPrev = data[0] ? true : false;
				this.hasNext = data[2] ? true : false;
				// Set space data from http request
				this.space = data[1];
				// Patch current version
				this.patchSpaceValues();
			})
		})
	}

	/** Check before submit */
	public onSubmit() {
		// If form is invalid
		if (this.form.invalid) {
			this.snackBar.open(
				'Validierung fehlgeschlagen. Bitte überprüfen Sie die rot makierten Eingabefelder!', 
				'Schließen', 
				{ duration: 10000, panelClass: 'error-snack' }
			)
			return;
		}
		// Reconvert displayed values back into database format
		this.space = this.reconvertDisplayedValue(this.form.getRawValue());
		// Add or edit space
		if (!this.isAddMode) this.editSpace();
		else this.addSpace();
	}

	/** POST new space */
	private addSpace() {
		this.dataService.postSpace(this.space).subscribe((data: any) => {
			this.snackBar.open('Der Parkraum wurde erfolgreich angelegt.', null, this.snackOptions);
			this.router.navigate(['/space', data.id, data.version]);
		})
	}

	/** PUT in existing space */
	private editSpace() {
		// Is current space status "PUBLISHED"
		const isPublished = this.space.publishingStatus === 'PUBLISHED' ? true : false;
		// Save edited space
		this.dataService.putSpace(this.spaceId, this.space).subscribe((data: any) => {
			// Update space from http request
			this.space = data;
			// Patch new data into space form
			this.patchSpaceValues();
			// If responsibility DB BahnPark else just update space with responsibility e.g. Parkopedia
			if ( this.space.responsibility === 'DBBAHNPARK' ) {
				this.navigateToVersion(this.space.version)				
				this.snackBar.open(
					isPublished ? `Ein neuer Entwurf (Version ${this.space.version}) wurde erstellt.` 
					: `Entwurf wurde erfolgreich aktualisiert.`, 
					null, 
					this.snackOptions
				);
			} else {
				this.snackBar.open(
					'Parkraum erfolgreich aktualisiert.', 
					null, 
					this.snackOptions
				);
			}
		})
	}

	/** PUBLISH draft space */
	public publishSpace() {
		// Changes detected or else publish space
		if ( this.detectChanges(this.patchedFormValue, this.form.getRawValue()) ) {
			this.snackBar.open(
				'Es wurden Änderungen an diesem Entwurf vorgenommen. Bitte speichern Sie diese vorher.', 
				'Okay!', 
				{ duration: 5000, panelClass: 'alert-snack' }
			)
		} else {
			this.dataService.publishSpace(this.spaceId, this.space.version).subscribe((data: any) => {
				this.space = data;
				this.snackBar.open('Parkraum erfolgreich publiziert.', null, this.snackOptions);
			})
		}
	}

	/** Detect if there are changes between two objects */
	detectChanges(obj1: any, obj2: any): boolean {
		return JSON.stringify(obj1) !== JSON.stringify(obj2)
	}

	/** Convert into displayed form vaules */
	convertDisplayedValues(space: Space): Space {
		// Format prices. Display currency format e.g. "1,20 €"
		if ( space.tariffPrices ) {
			space.tariffPrices.forEach((tariff: any) => {
				tariff.price = this.currency.transform(tariff.price, 'EUR', '€') || '';
			})
		}
		// Format geo locations. Replace dots with commas.
		if ( space.geoLocation.latitude ) space.geoLocation.latitude = space.geoLocation.latitude.toString().replace('.', ',');
		if ( space.geoLocation.latitude ) space.geoLocation.longitude = space.geoLocation.longitude.toString().replace('.', ',');

		return space;
	}

	/** Reconverts displayed values back into database format */
	reconvertDisplayedValue(space: Space): Space {
		// Format prices. Transform strings into numbers e.g. "1,20 €" => "1.2"
		if ( space.tariffPrices ) {
			space.tariffPrices.forEach((tariff: any) => {
				if ( tariff.price !== null ) tariff.price = parseFloat(tariff.price.replace('.', '').replace(',', '.')) || null
			})
		}
		// Format geo locations. Relpace commas with dots.
		if ( space.geoLocation ) {
			if ( space.geoLocation.latitude ) 
				typeof space.geoLocation.latitude === 'string' ? 
					space.geoLocation.latitude = space.geoLocation.latitude.replace(',', '.') : ''
			if ( space.geoLocation.longitude ) {
				typeof space.geoLocation.longitude === 'string' ? 
					space.geoLocation.longitude = space.geoLocation.longitude.replace(',', '.'): ''
			}
		}

		return space;
	}
	// JSON.parse(JSON.stringify(this.form.getRawValue())) Deepclone

	/** Build form */
	buildForm(): void {
		this.form = new UntypedFormGroup({});
		this.form = this.formBuilder.group({
			visibility: ['OFFLINE', [Validators.required]],
			visibilityDescription: [''],
			channels: [],
			station: this.formBuilder.group({
				id: [null],
				name: ['', [Validators.required]],
			}),
			responsibility: ['DBBAHNPARK', [Validators.required]],
			unit: this.formBuilder.group({
				id: [null],
				name: [''],
			}),
			label: [''],
			name: [''],
			nameInternal: [''],
			nameOptional: [''],
			nameDisplay: [''],
			spaceType: [''],
			spaceTypeEn: [''],
			spaceTypeName: [''],
			spaceNumber: [null],
			source: ['', [Validators.required]],
			sourceId: [null],
			slogan: [''],
			sloganEn: [''],
			url: [''],
			operator: [''],
			operatorUrl: [''],
			distance: [''],
			facilityType: [''],
			facilityTypeEn: [''],
			openingHours: [''],
			openingHoursEn: [''],
			hasOpeningHours24h: [''],
			numberParkingPlaces: [null],
			numberHandicapedPlaces: [null],
			hasHandicapedPlaces: [''],
			outOfServiceText: [''],
			outOfServiceTextEn: [''],
			reservation: [''],
			hasReservation: [''],
			isOutOfService: [''],
			isSpecialProductDb: [''],
			hasPrognosis: [''],
			hasChargingStation: [''],
			tariffFlags: this.formBuilder.group({
				isDiscountDbBahnCard: [false],
				isDiscountPCard: [false],
				isMonthVendingMachine: [false],
				isDiscountDbBahnComfort: [false],
				isTariffPaymentCustomerCards: [false],
				isDiscountDbParkAndRail: [false],
				isMonthParkAndRide: [false],
				isMonthSeason: [false],
				isTariffPaymentMobile: [false],
			}),
			tariffPrices: new UntypedFormArray([]),
			tariffInfo: this.formBuilder.group({
				tariffPaymentMobile: [''],
				tariffSpecialEn: [''],
				tariffRemarks: [''],
				tariffPointOfSaleEn: [''],
				tariffPaymentCustomerCards: [''],
				tariffRemarksEn: [''],
				tariffMaxParkingTime: [''],
				tariffNotesEn: [''],
				tariffFreeParkingTimeEn: [''],
				tariffNotes: [''],
				tariffNotesFormatted: [''],
				tariffNotesFormattedEn: [''],
				tariffDiscount: [''],
				tariffFreeParkingTime: [''],
				tariffIdentifier: [''],
				tariffMaxParkingTimeEn: [''],
				tariffSpecial: [''],
				tariffDiscountEn: [''],
				tariffNotesInternal: [''],
				tariffPaymentType: [''],
				tariffPaymentOptions: [''],
				tariffPointOfSale: [''],
				tariffPaymentOptionsEn: [''],
			}),
			spaceFlags: this.formBuilder.group({
				hasCctv: [false],
				isGated: [false],
				isLighted: [false],
				isAttended: [false],
				hasDisabledPlaces: [false],
				hasParentChildPlaces: [false],
				hasWomenPlaces: [false],
				isMotorCycleFriendly: [false],
				hasLift: [false],
				hasToilets: [false],
				hasSecurityFeatures: [false]
			}),
			spaceInfo: this.formBuilder.group({
				phone: [''],
				restrictions: [''],
				clearanceWidth: [''],
				clearanceHeight: [''],
				locationNightAccess: [''],
				allowedPropulsions: [''],
				chargingStation: ['']
			}),
			geoLocation: this.formBuilder.group({
				longitude: [null],
				latitude: [null]
			}),
			address: this.formBuilder.group({
				postalCode: [''],
				city: [''],
				street: [''],
				supplement: [''],
				supplementEn: ['']
			}),
			version: [null],
			bikePlacesInfo: [''],
			bikePlacesRental: [''],
			bikePlacesRentalUrl: [''],
			hasBikePlacesLocked: [''],
			hasBikePlacesOpen: [''],
			numberBikePlacesLocked: [''],
			numberBikePlacesOpen: [''],
			isParkspotting: [false]
		})
	}
}
