<h1>Ablaufdatum wählen</h1>
<p>Klicken Sie auf Fortfahren, um kein Ablaufdatum zu wählen</p>

<mat-card>
    <mat-calendar [(selected)]="expirationDate"></mat-calendar>
</mat-card>

<mat-card class="mt-4 bg-gui-blue-500">
    <h2 class="flex flex-row justify-between items-center">
        <span *ngIf="!expirationDate">Ohne Ablaufdatum fortfahren.</span>
        <span *ngIf="expirationDate" class="flex flex-row justify-between items-center w-full">
            Ablaufdatum: {{expirationDate | date:'dd.MM.yyyy'}}
            <button class="reset-date-btn" mat-icon-button (click)="expirationDate = ''" title="Datum löschen">
                <mat-icon>close</mat-icon>
            </button>
        </span>
    </h2>
</mat-card>

<div mat-dialog-actions class="justify-between">
    <button mat-flat-button mat-dialog-close>Abbrechen</button>
    <button mat-flat-button mat-dialog-close color="primary" cdkFocusInitial (click)="continue()">Forfahren</button>
</div>