<div class="flex flex-col pb-5">

    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        <div class="flex-item">
            <h1 class="mat-display-1">{{sitesCount}} Parkbereiche</h1>
        </div>
        <div class="flex-item">
            <!-- Import and Export buttons -->
            <app-import-export-buttons [params]="params" entity="sites"></app-import-export-buttons>
            <!-- Add new Site button -->
            <button [routerLink]="['/site/add']" mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Erstellen
            </button>
        </div>
    </div>
    
    <!-- Filter form -->
    <div class="flex flex-row justify-between items-center px-5">
        <app-site-filter (filterChanged)="filterChanged()"></app-site-filter>
    </div>

    <div class="flex flex-col w-100 px-5">
        
        <!-- Bulk edit and column buttons -->
        <div class="flex flex-row justify-start">
            <app-bulk-edit-button 
                [selection]="selection" 
                [entity]="bulkedit.entity" 
                [options]="bulkedit.options"
                class="mb-3">
            </app-bulk-edit-button>
        </div>
            
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Edit Site Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button [routerLink]="['/site/', element.id]" mat-icon-button color="primary"
                        aria-label="Parkbereiche bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Site ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"><code>{{element.id}}</code></td>
            </ng-container>

            <!-- Site Title Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Titel </th>
                <td mat-cell *matCellDef="let element">{{element.title}}</td>
            </ng-container>

            <!-- Site Visibility Column -->
            <ng-container matColumnDef="visibility">
                <th mat-header-cell *matHeaderCellDef> Sichtbarkeit </th>
                <td mat-cell *matCellDef="let element">
                    <span class="badge" [ngClass]="{
                        'online':element.visibility === 'ONLINE',
                        'offline':element.visibility === 'OFFLINE',
                        'pending':element.visibility === 'PENDING',
                        'hidden':element.visibility === 'HIDDEN',
                        'deleted':element.visibility === 'DELETED'
                    }">{{element.visibility | translate }}</span>
                </td>
            </ng-container>

            <!-- Site Station Column -->
            <ng-container matColumnDef="station">
                <th mat-header-cell *matHeaderCellDef> Bahnhof </th>
                <td mat-cell *matCellDef="let element">{{element.station?.name}}</td>
            </ng-container>

            <!-- Site Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <!-- Site Space Column -->
            <ng-container matColumnDef="space">
                <th mat-header-cell *matHeaderCellDef> Parkraum </th>
                <td mat-cell *matCellDef="let element">{{element.space?.name}}</td>
            </ng-container>

            <!-- Site Protocol Column -->
            <ng-container matColumnDef="protocol">
                <th mat-header-cell *matHeaderCellDef> Protokoll </th>
                <td mat-cell *matCellDef="let element">{{element.protocol}}</td>
            </ng-container>

            <!-- Site Destination ID Column -->
            <ng-container matColumnDef="destinationId">
                <th mat-header-cell *matHeaderCellDef> Destination ID </th>
                <td mat-cell *matCellDef="let element"><code>{{element.destinationId}}</code></td>
            </ng-container>

            <!-- Site Record Column -->
            <ng-container matColumnDef="isRecord">
                <th mat-header-cell *matHeaderCellDef> Belegungsdaten<br>aufzeichnen </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon>{{element.isRecord ? 'done' : 'close'}}</mat-icon>
                </td>
            </ng-container>

            <!-- Site Is Aggregate Column -->
            <ng-container matColumnDef="isAggregate">
                <th mat-header-cell *matHeaderCellDef> Aggregieren </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon>{{element.isAggregate ? 'done' : 'close'}}</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="15">Keine Daten gefunden.</td>
            </tr>

        </table>

        <mat-paginator [length]="sitesCount" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>

</div>