import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { DisplayedColumnsChanged, ColumnSetsStateModel } from './space-column-sets.action';

@State<ColumnSetsStateModel>({
	name: 'columnSets',
	defaults: {
		baseData: true,
		tariffControl: false,
		tariffPrices: false,
		tariffInfos: false
	}
})
@Injectable()
export class ColumnSetsState {
	@Action(DisplayedColumnsChanged)
	displayedColumnsChanged(ctx: StateContext<ColumnSetsStateModel>, action: DisplayedColumnsChanged) {
		ctx.setState({
			baseData: action.columnSet === 'baseData' ? true : false,
			tariffControl: action.columnSet === 'tariffControl' ? true : false,
			tariffPrices: action.columnSet === 'tariffPrices' ? true : false,
			tariffInfos: action.columnSet === 'tariffInfos' ? true : false,
		});
		// console.log(ctx.getState(), action)
	}
}