import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Space } from 'app/space/space.interface';
import { defer, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, first, ignoreElements, take, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ForkjoinWithProgressService {

	constructor() { }

	forkJoinWithProgress(arrayOfObservables: Observable<any>[]) {

		return defer(() => {

			let counter = 0;
			const percent$ = new Subject();

			const modifiedObservablesList = arrayOfObservables.map(
				(item) => item.pipe(
					finalize(() => {
						const percentValue = ++counter * 100 / arrayOfObservables.length;
						percent$.next(percentValue);
					}),
					catchError((error) => of({ isError: true, error: error }))
				)
			)

			const finalResult$ = forkJoin(modifiedObservablesList).pipe(
				tap(
					// res => { console.log(res) },
					// err => { console.log(err) },
					() => {
					percent$.next(100);
					percent$.complete();
				}),
				// catchError((error) => of({ isError: true, error: error }))
			);
			
			return of([finalResult$, percent$.asObservable()]);
		})

	}
}
