import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';

import { HttpClientXsrfModule } from '@angular/common/http';

import { environment } from '@environments/environment';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { getGermanPaginatorIntl } from '@core/intl/german-paginator.intl';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { AuthGuard } from '@core/guards/auth.guard';

// import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { UserState } from '@core/states/user/user.state';
import { AuthState, logoutPlugin } from '@core/states/auth/auth.state';

// import { FlexLayoutModule } from '@angular/flex-layout';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule, MatPaginatorIntl, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AppComponent } from './app.component';
import { HeaderComponent } from '@core/layout/header/header.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ClockComponent } from '@core/layout/header/clock/clock.component';
import { SpinnerComponent } from '@core/layout/spinner/spinner.component';
import { SpinnerInterceptor } from '@core/layout/spinner/spinner.interceptor';
import { MeDialogComponent } from '@core/layout/header/me-dialog/me-dialog.component';
import { LogoutButtonComponent } from '@core/layout/header/logout-button/logout-button.component';

import { CityListComponent } from './city/city-list/city-list.component';
import { CityAddEditComponent } from './city/city-add-edit/city-add-edit.component';
import { CityComponent } from './city/city.component';
import { CityFilterComponent } from './city/city-list/city-filter/city-filter.component';
import { CityParameterState } from './city/states/city-filter.state';

import { StationComponent } from './station/station.component';
import { StationListComponent } from './station/station-list/station-list.component';
import { StationAddEditComponent } from './station/station-add-edit/station-add-edit.component';
import { StationFilterComponent } from './station/station-list/station-filter/station-filter.component';

import { UnitComponent } from './unit/unit.component';
import { UnitListComponent } from './unit/unit-list/unit-list.component';
import { UnitFilterComponent } from './unit/unit-list/unit-filter/unit-filter.component';
import { UnitAddEditComponent } from './unit/unit-add-edit/unit-add-edit.component';
import { UnitParameterState } from './unit/states/unit-filter.state';

import { SpaceComponent } from './space/space.component';
import { SpaceListComponent } from './space/space-list/space-list.component';
import { SpaceAddEditComponent } from './space/space-add-edit/space-add-edit.component';
import { SpaceFilterComponent } from './space/space-list/space-filter/space-filter.component';
import { SpaceAutocompleteUnitsComponent } from './space/space-add-edit/space-autocomplete-units/space-autocomplete-units.component';
import { SpaceAutocompleteStationsComponent } from './space/space-add-edit/space-autocomplete-stations/space-autocomplete-stations.component';
import { SpaceColumnsetButtonComponent } from './space/space-list/space-columnset-button/space-columnset-button.component';
import { SpaceParameterState } from './space/states/space-filter.state';
import { SpacePricesFormComponent } from './space/space-add-edit/space-prices-form/space-prices-form.component';
import { ColumnSetsState } from './space/states/space-column-sets.state';

import { StationParameterState } from './station/states/station-filter.state';
import { StationAutocompleteCitiesComponent } from './station/station-add-edit/station-autocomplete-cities/station-autocomplete-cities.component';

import { SiteComponent } from './site/site.component';
import { SiteListComponent } from './site/site-list/site-list.component';
import { SiteAddEditComponent } from './site/site-add-edit/site-add-edit.component';
import { SiteFilterComponent } from './site/site-list/site-filter/site-filter.component';
import { SiteParameterState } from './site/states/site-filter.state';
import { SiteAutocompleteStationsComponent } from './site/site-add-edit/site-autocomplete-stations/site-autocomplete-stations.component';
import { SiteAutocompleteSpacesComponent } from './site/site-add-edit/site-autocomplete-spaces/site-autocomplete-spaces.component';

import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserAddEditComponent } from './user/user-add-edit/user-add-edit.component';

import { ChannelComponent } from './channel/channel.component';
import { ChannelListComponent } from './channel/channel-list/channel-list.component';
import { ChannelAddEditComponent } from './channel/channel-add-edit/channel-add-edit.component';

import { ApikeyComponent } from './apikey/apikey.component';
import { ApikeyAddEditComponent } from './apikey/apikey-add-edit/apikey-add-edit.component';
import { ApikeyListComponent } from './apikey/apikey-list/apikey-list.component';

import { ExportButtonDirective } from '@shared/directives/export-button.directive';
import { TariffPricesDirective } from '@shared/directives/tariff-prices.directive';

import { ImportDialogComponent } from '@shared/components/import-export-buttons/import-dialog/import-dialog.component';
import { ImportExportButtonsComponent } from '@shared/components/import-export-buttons/import-export-buttons.component';

import { EntityNamesPipe } from '@shared/pipes/entity-names.pipe';
// import { PricesPipe } from '@shared/pipes/prices.pipe';
import { TariffNamesPipe } from '@shared/pipes/tariff-names.pipe';
// import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import { CurrencyPipe } from '@angular/common';
import { TranslatePipe } from './shared/pipes/translate.pipe';
import { SalutationPipe } from './shared/pipes/salutation.pipe';

import { BulkEditButtonComponent } from '@shared/components/bulk-edit-button/bulk-edit-button.component';
import { BulkDeleteDialogComponent } from '@shared/components/bulk-edit-button/bulk-delete-dialog/bulk-delete-dialog.component';
import { BulkVisibilityDialogComponent } from '@shared/components/bulk-edit-button/bulk-visibility-dialog/bulk-visibility-dialog.component';
import { BulkChannelsDialogComponent } from '@shared/components/bulk-edit-button/bulk-channels-dialog/bulk-channels-dialog.component';
import { BulkPublishDialogComponent } from '@shared/components/bulk-edit-button/bulk-publish-dialog/bulk-publish-dialog.component';
import { ScrollToTopButtonComponent } from './core/layout/main/scroll-to-top-button/scroll-to-top-button.component';
import { DatepickerDialogComponent } from './apikey/apikey-add-edit/datepicker-dialog/datepicker-dialog.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { AssignmentsAutocompleteComponent } from './apikey/apikey-add-edit/assignments-autocomplete/assignments-autocomplete.component';
import { HttpXsrfInterceptor } from '@core/interceptors/http-xsrf.interceptor';

import { MobiComponent } from './mobi/mobi.component';
import { MobiListComponent } from './mobi/mobi-list/mobi-list.component';
import { MobiParameterState } from './mobi/states/mobi-filter.state';
import { MobiFilterComponent } from './mobi/mobi-list/mobi-filter/mobi-filter.component';
import { MobiAddEditComponent } from './mobi/mobi-add-edit/mobi-add-edit.component';
import { MobiAutocompleteStationsComponent } from './mobi/mobi-add-edit/mobi-autocomplete-stations/mobi-autocomplete-stations.component';
import { UserFilterComponent } from './user/user-list/user-filter/user-filter.component';
import { UserParameterState } from './user/states/user-filter.state';
import { ApikeyFilterComponent } from './apikey/apikey-list/apikey-filter/apikey-filter.component';
import { ApikeyParameterState } from './apikey/states/apikey-filter.state';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    ClockComponent,
    SpinnerComponent,
    MeDialogComponent,
    LogoutButtonComponent,
    // OrderByPipe,
    CityListComponent,
    CityAddEditComponent,
    CityComponent,
    StationComponent,
    StationListComponent,
    StationAddEditComponent,
    StationFilterComponent,
    UnitComponent,
    CityFilterComponent,
    UnitListComponent,
    UnitFilterComponent,
    UnitAddEditComponent,
    SpaceComponent,
    SpaceListComponent,
    SpaceAddEditComponent,
    SpaceFilterComponent,
    TariffNamesPipe,
    TariffPricesDirective,
    SpaceAutocompleteUnitsComponent,
    SpaceAutocompleteStationsComponent,
    SpaceColumnsetButtonComponent,
    SpacePricesFormComponent,
    // PricesPipe,
    SiteComponent,
    SiteListComponent,
    SiteAddEditComponent,
    SiteFilterComponent,
    SiteAutocompleteStationsComponent,
    SiteAutocompleteSpacesComponent,
    ChannelComponent,
    UserComponent,
    ChannelListComponent,
    ChannelAddEditComponent,
    StationAutocompleteCitiesComponent,
    UserListComponent,
    UserAddEditComponent,
    ExportButtonDirective,
    ImportDialogComponent,
    ImportExportButtonsComponent,
    EntityNamesPipe,
    BulkEditButtonComponent,
    BulkDeleteDialogComponent,
    BulkVisibilityDialogComponent,
    BulkChannelsDialogComponent,
    BulkPublishDialogComponent,
    TranslatePipe,
    ScrollToTopButtonComponent,
    ApikeyComponent,
    ApikeyAddEditComponent,
    ApikeyListComponent,
    SalutationPipe,
    DatepickerDialogComponent,
    ConfirmationDialogComponent,
    AssignmentsAutocompleteComponent,
    MobiComponent,
    MobiListComponent,
    MobiFilterComponent,
    MobiAddEditComponent,
    MobiAutocompleteStationsComponent,
    UserFilterComponent,
    ApikeyFilterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // AngularStickyThingsModule,
    //  FlexLayoutModule,
    BrowserModule,
    EditorModule,
   
    MatInputModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatListModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    ClipboardModule,

    NgxsModule.forRoot([
      AuthState, 
      UserState, 
      ColumnSetsState, 
      SpaceParameterState, 
      UserParameterState,
      ApikeyParameterState,
      SiteParameterState,
      CityParameterState,
      StationParameterState,
      UnitParameterState,
      MobiParameterState
    ], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),

  ],
  exports: [HttpClientModule],
  providers: [
    AuthGuard,
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    TariffPricesDirective,
    ExportButtonDirective,
    { provide: NGXS_PLUGINS, useValue: logoutPlugin, multi: true },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { pageSizeOptions: [10, 25, 50, 100, 250, 500], pageSize: 25 } },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        registerLocaleData(localeDe);
    }
 }
