import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MAT_PAGINATOR_DEFAULT_OPTIONS, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { Select, Store } from '@ngxs/store';
import { BulkEdit } from '@shared/components/bulk-edit-button/bulk-edit.interface';
import { EventService } from '@shared/services/event.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnitParameterSelected, UnitParametersModel } from '../states/unit-filter.action';
import { UnitParameterState } from '../states/unit-filter.state';
import { Unit } from '../unit.interface';

@Component({
	selector: 'app-unit-list',
	templateUrl: './unit-list.component.html',
	styleUrls: ['./unit-list.component.scss']
})
export class UnitListComponent implements OnInit {

	displayedColumns: string[] = [
		'select',
		'edit',
		'id',
		'name',
		'bahnstelle',
		'regionalDistrict',
		'costCenterContipark'
	]

	dataSource = new MatTableDataSource<Unit[]>();

	selection = new SelectionModel<Unit[]>(true, []);

	unitsCount: number;

	params: any

	bulkedit: BulkEdit = { entity: 'units', options: ['delete'] }

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	@Select(UnitParameterState) UnitParameterState$: Observable<UnitParametersModel>

	destroy: Subject<boolean> = new Subject<boolean>();

	constructor(
		@Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) private paginatorDefaults: MatPaginator,
		private dataService: DataService,
		private eventService: EventService,
		private store: Store,
	) { }

	ngOnInit(): void {

		// Select current filter params
		this.params = this.store.selectSnapshot(UnitParameterState.getCurrentParams);
		// If state not set, dispatch defaults
		if (!this.params) {
			this.store.dispatch(new UnitParameterSelected({
				size: this.paginatorDefaults.pageSize,
				page: 0
			}))
		}

		// Subscribe to FilterParamater state and get Units
		this.UnitParameterState$
			.pipe(
				takeUntil(this.destroy)
			)
			.subscribe((data: UnitParametersModel) => {
				// Clear all checkboxes because of serverside pagination
				this.selection.clear();
				// Using spread because data is a read only object
				this.params = { ...data }
				
				this.updateUnits();	
			})

		// Subscribe to child event bulk edit
		this.eventService.eventListner()
			.pipe(takeUntil(this.destroy))
			.subscribe((data: any) => {
				this.updateUnits();
				// Clear selection
				this.selection.clear()
			})

	}

	ngOnDestroy(): void {
		// Unsubscribe
		this.destroy.next(true);
		this.destroy.unsubscribe();
	}

	updateUnits(): void {
		this.dataService.getUnits(this.params).subscribe((data: any) => {
			this.unitsCount = data.totalElements;
			this.dataSource.data = data.content;
			// Has to be due to lifecycle timing issues, maybe there is a better workaround
			setTimeout(() => {
				this.paginator.length = data.totalElements
				this.paginator.pageIndex = this.params.page
			})
		})
	}

	/** Dispatch paginator event */
	pageChanged(event: any) {
		this.params.size = event.pageSize
		this.params.page = event.pageIndex

		this.store.dispatch(new UnitParameterSelected(this.params))
	}

	/** Go to first page whenever changes to the filter were made */
	filterChanged() {
		this.paginator.firstPage();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string { // TODO: add <Stations> model
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}
}
