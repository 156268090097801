import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { City } from '../city.interface';
// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};

@Component({
	selector: 'app-city-add-edit',
	templateUrl: './city-add-edit.component.html',
	styleUrls: ['./city-add-edit.component.scss'],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	]
})
export class CityAddEditComponent implements OnInit {

	cityId: number;

	city: City;

	isAddMode: boolean;

	form: UntypedFormGroup

	changesDetected: boolean = false;

	snackOptions: MatSnackBarConfig = { duration: 5000, panelClass: 'success-snack' };

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.cityId = this.route.snapshot.params['id'];
		this.isAddMode = !this.cityId;

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			name: new UntypedFormControl('', [Validators.required]),
			geoLocation: this.formBuilder.group({
				longitude: new UntypedFormControl(null),
				latitude: new UntypedFormControl(null),
			})
		})

		if (!this.isAddMode) {
			this.dataService.getCity(this.cityId).subscribe((data: City) => {
				this.city = data;
				this.form.patchValue(data)
				// Detect form changes after values patched
				this.form.valueChanges.subscribe(() => { this.changesDetected = true })
			})
		}

	}

	onSubmit() {
		if (this.form.invalid) return;

		if (this.isAddMode) this.addCity();
		else this.editCity();
	}

	private addCity() {
		this.dataService.postCity(this.form.value).subscribe((data: City) => {
			this.snackBar.open('Stadt erfolgreich erstellt.', null, this.snackOptions);
			this.router.navigate(['/city', data.id]);
		})
	}

	private editCity() {
		this.dataService.putCity(this.cityId, this.form.value).subscribe((data: City) => {
			this.city = data;
			this.snackBar.open('Stadt erfolgreich aktualisiert.', null, this.snackOptions);
		})
	}

}
