import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';

import { LoginComponent } from './login/login.component';

import { HomeComponent } from './home/home.component';

import { CityComponent } from './city/city.component';
import { CityListComponent } from './city/city-list/city-list.component';
import { CityAddEditComponent } from './city/city-add-edit/city-add-edit.component';

import { StationComponent } from './station/station.component';
import { StationListComponent } from './station/station-list/station-list.component';
import { StationAddEditComponent } from './station/station-add-edit/station-add-edit.component';

import { UnitComponent } from './unit/unit.component';
import { UnitListComponent } from './unit/unit-list/unit-list.component';
import { UnitAddEditComponent } from './unit/unit-add-edit/unit-add-edit.component';

import { SpaceComponent } from './space/space.component';
import { SpaceListComponent } from './space/space-list/space-list.component';
import { SpaceAddEditComponent } from './space/space-add-edit/space-add-edit.component';

import { MobiComponent } from './mobi/mobi.component';
import { MobiListComponent } from './mobi/mobi-list/mobi-list.component';
import { MobiAddEditComponent } from './mobi/mobi-add-edit/mobi-add-edit.component';

import { SiteComponent } from './site/site.component';
import { SiteListComponent } from './site/site-list/site-list.component';
import { SiteAddEditComponent } from './site/site-add-edit/site-add-edit.component';

import { ChannelComponent } from './channel/channel.component';
import { ChannelListComponent } from './channel/channel-list/channel-list.component';
import { ChannelAddEditComponent } from './channel/channel-add-edit/channel-add-edit.component';

import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserAddEditComponent } from './user/user-add-edit/user-add-edit.component';
import { ApikeyComponent } from './apikey/apikey.component';
import { ApikeyListComponent } from './apikey/apikey-list/apikey-list.component';
import { ApikeyAddEditComponent } from './apikey/apikey-add-edit/apikey-add-edit.component';
import { RoleGuard } from '@core/guards/role.guard';



const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        // loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'city',
        component: CityComponent,
        children: [
            { path: '', component: CityListComponent },
            { path: 'add', component: CityAddEditComponent },
            { path: ':id', component: CityAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'station',
        component: StationComponent,
        children: [
            { path: '', component: StationListComponent },
            { path: 'add', component: StationAddEditComponent },
            { path: ':id', component: StationAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'unit',
        component: UnitComponent,
        children: [
            { path: '', component: UnitListComponent },
            { path: 'add', component: UnitAddEditComponent },
            { path: ':id', component: UnitAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'space',
        component: SpaceComponent,
        children: [
            { path: '', component: SpaceListComponent },
            { path: 'add', component: SpaceAddEditComponent },
            { path: ':id/:version', component: SpaceAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'site',
        component: SiteComponent,
        children: [
            { path: '', component: SiteListComponent },
            { path: 'add', component: SiteAddEditComponent },
            { path: ':id', component: SiteAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'mobi',
        component: MobiComponent,
        children: [
            { path: '', component: MobiListComponent },
            { path: 'add', component: MobiAddEditComponent },
            { path: ':id/:version', component: MobiAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'channel',
        component: ChannelComponent,
        children: [
            { path: '', component: ChannelListComponent },
            { path: 'add', component: ChannelAddEditComponent },
            { path: ':id', component: ChannelAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'user',
        component: UserComponent,
        children: [
            { path: '', component: UserListComponent },
            { path: 'add', component: UserAddEditComponent },
            { path: ':id', component: UserAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: 'apikey',
        component: ApikeyComponent,
        children: [
            { path: '', component: ApikeyListComponent },
            { path: 'add', component: ApikeyAddEditComponent },
            { path: ':id', component: ApikeyAddEditComponent }
        ],
        canActivate: [AuthGuard, RoleGuard],
    },
    {
        path: '**',
        component: LoginComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
