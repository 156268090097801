export class TinymceConfig {

    public static settings = {
        base_url: '/tinymce', 
        language_url : '/assets/langs/de.js',
        language: 'de',
        content_style: 'body { font-size: 14px; color: #005b9f }',
        deprecation_warnings: false, // force_p_newlines and forced_root_block are desprecated
        force_p_newlines : false,
        forced_root_block : '',
        // icons: 'material', -> premium only
        branding: false,
        height: 150,
        menubar: false,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'charmap', 'code', 'textcolor'
        ],
        toolbar_mode: 'floating',
        toolbar: `undo redo | removeformat | bold italic underline forecolor | link | charmap | code | `,
        link_class_list: [
            {title:'Link in Schwarz', value:''}, 
            {title:'Link in Rot', value:'dbbp-link'}
        ],
        color_cols: 6,
        color_map: [
            '#BFEDD2', 'Light Green',
            '#FBEEB8', 'Light Yellow',
            '#F8CAC6', 'Light Red',
            '#ECCAFA', 'Light Purple',
            '#C2E0F4', 'Light Blue',
            '#2DC26B', 'Green',
            '#F1C40F', 'Yellow',
            '#E03E2D', 'Red',
            '#B96AD9', 'Purple',
            '#3598DB', 'Blue',
            '#169179', 'Dark Turquoise',
            '#E67E23', 'Orange',
            '#BA372A', 'Dark Red',
            '#843FA1', 'Dark Purple',
            '#236FA1', 'Dark Blue',
            '#ECF0F1', 'Light Gray',
            '#CED4D9', 'Medium Gray',
            '#95A5A6', 'Gray',
            '#7E8C8D', 'Dark Gray',
            '#34495E', 'Navy Blue',
            '#000000', 'Black',
            '#ffffff', 'White',
            // Custom BahnPark colors
            '#F01414', 'DB BahnPark Rot',
            '#014171', 'DB BahnPark Blau',
            '#CCCCCC', 'DB BahnPark Grau'
        ]
    }

}