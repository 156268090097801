import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'salutation'
})
export class SalutationPipe implements PipeTransform {

	transform(value: string): string | null {

		if (value === 'MS') return 'Frau'
		if (value === 'MR') return 'Herr'
		if (value === 'DIVERSE') return ''
		if (value === 'NONE') return ''

		else return value
  	}

}
