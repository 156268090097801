import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';

@Component({
	selector: 'app-import-export-buttons',
	templateUrl: './import-export-buttons.component.html',
	styleUrls: ['./import-export-buttons.component.scss']
})
export class ImportExportButtonsComponent {

	@Input() params: any;
	@Input() entity: string;

	constructor(
		public dialog: MatDialog
	) { }

	openImportDialog(): void {
		this.dialog.open(ImportDialogComponent, {
			width: '40%',
			data: this.entity
		})
	}

}
