import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { StationParameterReset, StationParameterSelected } from 'app/station/states/station-filter.action';
import { StationParameterState } from 'app/station/states/station-filter.state';
import { Station } from 'app/station/station.interface';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
	selector: 'app-station-filter',
	templateUrl: './station-filter.component.html',
	styleUrls: ['./station-filter.component.scss']
})
export class StationFilterComponent implements OnInit {

	@Output() filterChanged = new EventEmitter<any>();

	form: UntypedFormGroup

	onKeyup = new Subject<KeyboardEvent>();

	visibilityOptions = [
		{'name': 'Online', 'value': 'ONLINE'},
		{'name': 'Offline', 'value': 'OFFLINE'},
		{'name': 'Ausstehend', 'value': 'PENDING'},
		{'name': 'Gelöscht', 'value': 'DELETED'},
		{'name': 'Versteckt', 'value': 'HIDDEN'}
	];

	channelOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBP'},
		{'name': 'Parken am Bahnhof', 'value': 'PAB'},
		{'name': 'OpenData', 'value': 'OPENDATA'},
		{'name': 'PDF', 'value': 'PDF'},
		{'name': 'PIT', 'value': 'PIT'},
		{'name': 'bahn.de', 'value': 'BAHN'},
		{'name': 'bahnhof.de', 'value': 'BAHNHOF'},
		{'name': 'Kundenfeedback', 'value': 'FEEDBACK'},
		{'name': 'Dashboard', 'value': 'DASHBOARD'}
	];

	sourceOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBAHNPARK'},
		{'name': 'Parkopedia', 'value': 'PARKOPEDIA'},
	];

	responsibilityOptions = [
		{'name': 'DB BahnPark', 'value': 'DBBAHNPARK'},
		{'name': 'Partner', 'value': 'PARTNER'},
		{'name': 'Fremd', 'value': 'FOREIGN'}
	]

	constructor(
		private formBuilder: UntypedFormBuilder,
		private store: Store
	) { }

	ngOnInit(): void {

		this.form = new UntypedFormGroup({});

        this.form = this.formBuilder.group({
			name: new UntypedFormControl(''),
            visibility: new UntypedFormControl(''),
			channel: new UntypedFormControl(''),
			source: new UntypedFormControl(''),
			responsibility: new UntypedFormControl('')
        })

		this.form.patchValue(
			this.store.selectSnapshot(StationParameterState.getCurrentParams)
		)

		this.onKeyup.pipe(
			map((event: any) => event.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(() => { 
			this.onSelect() 
		});
		
	}

	onSelect() {
		this.filterChanged.emit()
		this.store.dispatch(new StationParameterSelected(this.form.value))
	}

	resetFilter() {
		this.form.reset();
		this.store.dispatch(new StationParameterReset())
	}


}
