<mat-form-field fxLayout="column" formGroupName="station">
    <mat-label>Bahnhof</mat-label>
    <input type="text" placeholder="Bahnhof suchen..." aria-label="Bahnhof" matInput 
        formControlName="name" 
        [matAutocomplete]="autoStation"
        (keyup)="onKeyup()" 
        required>
    <mat-autocomplete #autoStation="matAutocomplete">
        <mat-option *ngFor="let s of filteredStations | async" [value]="s.name" (onSelectionChange)="selectionChange(s.id)">
            {{s.name}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="station.get('name').hasError('required')">
        Bitte wählen Sie einen Bahnhof aus der Liste
    </mat-error>
</mat-form-field>