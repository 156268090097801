<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        
		<div class="flex items-center justify-start">
			<div *ngIf="!isAddMode">
                <div class="user-status-badge" [ngClass]="user?.userStatus">{{user?.userStatus | translate}}</div> 
            </div>
			<div>
				<h1 class="mat-display-1" *ngIf="isAddMode">Neuen API Benutzer erstellen</h1>
				<h1 class="mat-display-1" *ngIf="!isAddMode">API Benutzer bearbeiten: <b>{{user?.username}}</b></h1>
				<span class="mat-caption" *ngIf="!isAddMode">
					<b>ID:</b> {{userId}} |
					<b>Erzeugt:</b> {{user?.created | date: 'dd.MM.yyyy - HH:mm'}} Uhr
				</span>
			</div>
		</div>

		<div>
			<button type="submit" mat-raised-button color="primary" form="ngForm">
				SPEICHERN
			</button>
			<button [routerLink]="['/apikey']" type="button" mat-raised-button class="!ml-[16px]">
				ZURÜCK
			</button>
		</div>

	</div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards md:masonry-mat-cards-grid-1 xl:masonry-mat-cards-grid-4 px-5 pt-1">

			<mat-card>

				<mat-card-title>API Benutzer</mat-card-title>

				<mat-card-content class="!flex flex-col">

					<mat-form-field>
						<mat-label>E-Mail Adresse</mat-label>
						<input matInput formControlName="emailAddress">
						<mat-error *ngIf="form.get('emailAddress').hasError('email')">
							Bitte geben Sie eine valide E-Mail Adresse ein.
						</mat-error>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Benutzername</mat-label>
						<input matInput formControlName="username" required>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Anrede</mat-label>
						<mat-select formControlName="salutation">
							<mat-option *ngFor="let option of salutationOptions" [value]="option.value">{{option.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Vorname</mat-label>
						<input matInput formControlName="firstname">
					</mat-form-field>

					<mat-form-field>
						<mat-label>Nachname</mat-label>
						<input matInput formControlName="lastname">
					</mat-form-field>

				</mat-card-content>

				<mat-card-actions class="!flex flex-row justify-between" *ngIf="!isAddMode">
					<button *ngIf="user?.userStatus === 'ACTIVE'" 
							mat-button 
							color="primary" 
							type="button"
							(click)="changeUserStatus('LOCKED')">
						<mat-icon class="mr-2">person_off</mat-icon>Nutzer sperren
					</button>
					<button *ngIf="user?.userStatus !== 'ACTIVE'" 
							mat-button
							color="primary" 
							type="button"
							(click)="changeUserStatus('ACTIVE')">
						<mat-icon class="mr-2">person</mat-icon>Nutzer entsperren
					</button>
					<button mat-button 
							color="warn" 
							type="button"
							(click)="deleteUser()">
						<mat-icon class="mr-2">person_remove</mat-icon>Nutzer löschen
					</button>
				</mat-card-actions>

			</mat-card>

			<mat-card>

				<mat-card-title>API Verwaltung</mat-card-title>

				<mat-card-content class="!flex flex-col">

					<mat-form-field>
						<mat-label>Schnittstelle</mat-label>
						<mat-select formControlName="api" required (valueChange)="onApiChange($event)">
							<mat-option *ngFor="let option of apiOptions" [value]="option.value">
								{{option.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Ausspielkanal</mat-label>
						<mat-select formControlName="channel">
							<mat-option [value]="null">Kein Ausspielkanal ausgewählt</mat-option>
							<mat-option *ngFor="let option of channelOptions" [value]="option.value">{{option.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>

				</mat-card-content>

			</mat-card>

			<mat-card class="md:col-span-1 xl:col-span-2">

				<mat-card-title>
					API Keys ({{apiKeys?.length}})
				</mat-card-title>

				<mat-card-content>

					<mat-accordion>
						<mat-expansion-panel *ngFor="let key of apiKeys" [expanded]="key.panelOpenState" class="mat-elevation-z1">
							<mat-expansion-panel-header>
								<mat-panel-title class="!flex flex-row items-center" [ngClass]="isDateExpired(key.expirationDate) ? 'expired' : 'valid'">
									<mat-icon>{{isDateExpired(key.expirationDate) ? 'cancel' : 'check_circle'}}</mat-icon>
								</mat-panel-title>
								<mat-panel-description class="!flex flex-row justify-between !basis-full" [ngClass]="isDateExpired(key.expirationDate) ? 'expired' : ''">
									<span>{{key.token}}</span>
									<span>{{ key.expirationDate ? (key.expirationDate | date: 'dd.MM.yyyy') : 'Kein Ablaufdatum' }}</span>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div *ngIf="key.value">
								<div class="token">
									<button mat-icon-button type="button" 
										aria-label="Copy to Clipboard" 
										matTooltip="In Zwischenablage kopieren"
										[cdkCopyToClipboard]="key.value"
										(click)="$event.stopPropagation();">
										<mat-icon>content_copy</mat-icon>
									</button>
									<code>{{key.value}}</code>
								</div>
								<div class="flex flex-row justify-evenly items-center token-alert">
									<mat-icon class="me-2">tips_and_updates</mat-icon> 
									<p>Achtung! Dieser API Key ist nur ein einziges Mal sichtbar. Merken Sie sich ihn gut! Falls Sie ihn doch mal 
										vergessen sollten, müssen Sie einen Neuen generieren. Der Alte kann dann gelöscht werden.</p>
								</div>
							</div>
							<div class="flex flex-row justify-between items-center">
								<small class="w-2/3">
									Der API Key wird nur beim Erstellen vollständig angezeigt. Sie können das Ablaufdatum erneuern oder den API Key löschen.
								</small>
								<div>
									<button mat-flat-button color="primary" class="!me-2" type="button" (click)="renewApikey(key.userId, key.id)">
										<mat-icon>autorenew</mat-icon> Erneuern
									</button> 
									<button mat-flat-button color="warn" type="button" (click)="removeApikey(key.userId, key.id)">
										<mat-icon>delete</mat-icon> Löschen
									</button> 
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

				</mat-card-content>

				<mat-card-actions>
					<button mat-button color="primary" type="button" (click)="createApikey(userId)">
						<mat-icon>add</mat-icon> Neuen API Key generieren
					</button>
				</mat-card-actions>
	
				<app-assignments-autocomplete 
					*ngIf="['OPENDATA', 'OPENDATA_EXT'].includes(user?.api) && user?.channel === null && apiKeys?.length">
				</app-assignments-autocomplete>

				<div *ngIf="!canDoAssignments" class="token-alert" fxLayout="row" fxLayoutAlign="space-evenly center">
					<mat-icon [style.margin-right.rem]="1">tips_and_updates</mat-icon> {{assignmentsText}}
				</div>

			</mat-card>

		</div>

	</form>

</div>