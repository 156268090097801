import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MAT_PAGINATOR_DEFAULT_OPTIONS, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';
import { Channel } from '../channel.interface';

@Component({
	selector: 'app-channel-list',
	templateUrl: './channel-list.component.html',
	styleUrls: ['./channel-list.component.scss']
})
export class ChannelListComponent implements OnInit {

	displayedColumns: string[] = [
		// 'select',
		// 'edit',
		// 'id',
		'token',
		// 'name',
		'description'
	]
	dataSource = new MatTableDataSource<Channel[]>();
	selection = new SelectionModel<Channel[]>(true, []);
	sortedData: Channel[];

	pageEvent: PageEvent;

	channelsCount: number;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private dataService: DataService,
	) { }

	ngOnInit(): void {

		this.dataService.getChannels().subscribe((data: any) => {

			this.channelsCount = data.totalElements;
			this.dataSource.paginator = this.paginator;

			/** init table sort alread sorted by name ASC 
			this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
			this.dataSource.sortingDataAccessor = (item: any, property: any) => {
				switch (property) {
					case 'id': return item.id;
					case 'name': return item.name;
					default: return item[property];
				}
			};

			this.dataSource.sort = this.sort;
			*/

			this.dataSource.data = data;

		})

	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string { // TODO: add <Cites> model
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}

	/** Keyup filter event */
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
}
