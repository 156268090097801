import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SpaceColumnSetsService {

	constructor() { }

	getBaseDataColumns(): string[] {
		return [
			'select',
			'edit',
			'id',
			'title',
			'nameInternal',
			'publishingStatus',
			'visibility',
			'channels',
			'source',
			'operator',
			'spaceNumber',
			'numberParkingPlaces',
			'facilityType',
			'address',
			'edited'
		]
	}

	getTariffControlColumns(): string[] {
		return [
			'select',
			'edit',
			'id',
			'title',
			'isDiscountDbParkAndRail',
			'isDiscountDbBahnComfort',
			'isDiscountPCard',
			'isMonthVendingMachine',
			'isMonthParkAndRide',
			'isMonthSeason'
		]
	}

	getTariffPricesColumns(): string[] {
		return [
			'select',
			'edit',
			'id',
			'title',
			'20min',
			'30min',
			'1hour',
			'1day',
			'1dayPCard',
			'1week',
			'1weekDiscount',
			'1monthVendingMachine',
			'1monthLongTerm',
			'1monthReservation',
		]
	}

	getTariffInfosColumns(): string[] {
		return [
			'select',
			'edit',
			'id',
			'title',
			'tariffFreeParkingTime',
			'tariffFreeParkingTimeEn',
			'tariffMaxParkingTime',
			'tariffMaxParkingTimeEn',
			'tariffIdentifier',
			'tariffPointOfSale',
			'tariffPointOfSaleEn',
			'tariffDiscount',
			'tariffDiscountEn',
			'tariffSpecial',
			'tariffSpecialEn',
			'tariffNotes',
			'tariffNotesEn',
			'tariffNotesInternal',
			'tariffRemarks',
			'tariffRemarksEn',
			'tariffPaymentOptions',
			'tariffPaymentOptionsEn',
			'tariffPaymentMobile',
			'tariffPaymentCustomerCards'
			// tariffPaymentType ?? was missing in old admintool
		]
	}

}
