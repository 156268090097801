<h1 mat-dialog-title class="!flex flex-row items-center">
    <mat-icon>verified_user</mat-icon> {{(me$ | async)?.salutation | salutation}} {{(me$ | async)?.firstname}} {{(me$ | async)?.lastname}}
</h1>

<mat-dialog-content class="max-w-[500px] !py-5 !flex flex-col">

    <table class="user-table mb-5">
        <tbody>
            <tr>
                <td>Benutzername</td>
                <td>{{(me$ | async)?.username}}</td>
            </tr>
            <tr>
                <td>Benutzerrolle</td>
                <td>{{(me$ | async)?.role}}</td>
            </tr>
            <tr>
                <td>Benutzerstatus</td>
                <td>{{(me$ | async)?.userStatus}}</td>
            </tr>
        </tbody>
    </table>

    <h2 class="subheading-2">Passwort ändern</h2>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" class="!flex flex-col">

        <mat-form-field class="mb-5" appearance="outline">
            <mat-label>Altes Passwort</mat-label>
            <input [type]="hideVisibilityOldPw ? 'password' : 'text'" matInput formControlName="oldPassword" maxlength="72" required>
            <a matSuffix mat-icon-button tabindex="-1"> 
                <mat-icon (click)="hideVisibilityOldPw = !hideVisibilityOldPw" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideVisibilityOldPw">
                    {{hideVisibilityOldPw ? "visibility_off" : "visibility"}}</mat-icon>
            </a>
            <mat-hint>8-72 Zeichen, mind. ein kleiner, ein großer Buchstabe, eine Ziffer, Name darf nicht im Passwort vorkommen.</mat-hint>
        </mat-form-field>
        
        <mat-form-field class="mb-5" appearance="outline">
            <mat-label>Neues Passwort</mat-label>
            <input [type]="hideVisibilityNewPw ? 'password' : 'text'" matInput #password formControlName="newPassword" maxlength="72" required>
            <a matSuffix mat-icon-button tabindex="-1"> 
                <mat-icon (click)="hideVisibilityNewPw = !hideVisibilityNewPw" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideVisibilityNewPw">
                    {{hideVisibilityNewPw ? "visibility_off" : "visibility"}}</mat-icon>
            </a>
            <mat-hint><strong>{{password.value.length}} / 72</strong></mat-hint>
        </mat-form-field>

        <button type="submit" mat-raised-button>Passwort ändern</button>

    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" mat-dialog-close>Schlie&szlig;en</button>
</mat-dialog-actions>
