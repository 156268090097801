import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { DisplayedColumnsChanged } from 'app/space/states/space-column-sets.action';

@Component({
	selector: 'app-space-columnset-button',
	templateUrl: './space-columnset-button.component.html',
	styleUrls: ['./space-columnset-button.component.scss']
})
export class SpaceColumnsetButtonComponent {

	constructor(
		private store: Store
	) { }

	/** Column Set dispatcher */
	changeColumnSet(name: string) {
		this.store.dispatch(new DisplayedColumnsChanged(name))
	}

}
