<h1 mat-dialog-title>Sichtbarkeit bearbeiten</h1>
<mat-dialog-content>

    <form [formGroup]="form">
        <label id="visibility-bulkedit-label">Sichtbarkeit für {{data.selected.length}} markierte Element(e) ändern.</label>
        <mat-radio-group
            aria-labelledby="visibility-bulkedit-label"
            class="visibility-bulkedit"
            formControlName="visibility">
            <mat-radio-button [value]="'ONLINE'">Online</mat-radio-button>
            <mat-radio-button [value]="'OFFLINE'">Offline</mat-radio-button>
            <mat-radio-button [value]="'PENDING'" disabled>Prüfen</mat-radio-button>
            <mat-radio-button [value]="'HIDDEN'">Versteckt</mat-radio-button>
        </mat-radio-group>
        <mat-form-field fxLayout="column" appearance="outline">
            <mat-label>Grund</mat-label>
            <input type="text" matInput formControlName="visibilityDescription">
        </mat-form-field>
    </form>

    <div *ngIf="progress" [style.margin-top.px]="32" [style.margin-bottom.px]="32">
        <mat-progress-bar *ngIf="progress" [color]="'primary'" [value]="progress"></mat-progress-bar>
        <small>Fortschritt: {{progress}}%</small>
    </div>

    <mat-card class="error-card" *ngIf="errors?.length > 0">
        <ol>
            <li *ngFor="let e of errors">{{e.error.error.message}}</li>
        </ol>
    </mat-card>

    <mat-card class="success-card" *ngIf="errors?.length == 0">
        Massenverarbeitung erfolgreich. Das Fenster kann geschlossen werden.
    </mat-card>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="progress === 100" mat-flat-button mat-dialog-close color="primary">Schließen</button>
    <button *ngIf="progress < 100" mat-flat-button mat-dialog-close>Schließen</button>
    <button *ngIf="progress < 100" mat-flat-button color="primary" (click)="bulkEditVisibility()">Speichern</button>
</mat-dialog-actions>