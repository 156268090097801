<mat-form-field fxLayout="column" formGroupName="city">
    <mat-label>Stadt</mat-label>
    <input type="text" placeholder="Stadt suchen..." aria-label="Stadt" matInput 
        formControlName="name" 
        [matAutocomplete]="autoCity"
        (keyup)="onKeyup()" 
        required>
    <mat-autocomplete #autoCity="matAutocomplete">
        <mat-option *ngFor="let c of filteredCities | async" [value]="c.name" (onSelectionChange)="selectionChange(c.id)">
            {{c.name}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="city.get('name').hasError('required')">
        Bitte wählen Sie eine Stadt aus der Liste
    </mat-error>
</mat-form-field>