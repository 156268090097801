<button mat-raised-button class="mat-elevation-z0 mat-border" [matMenuTriggerFor]="columnSets" aria-label="Change column sets" [style]="">
    <mat-icon>menu</mat-icon>
</button>
<mat-menu #columnSets="matMenu">
    <button mat-menu-item (click)="changeColumnSet('baseData')">
        <mat-icon>folder</mat-icon>
        <span>Stammdaten</span>
    </button>
    <button mat-menu-item (click)="changeColumnSet('tariffControl')">
        <mat-icon>gamepad</mat-icon>
        <span>Tarif Steuerung</span>
    </button>
    <button mat-menu-item (click)="changeColumnSet('tariffPrices')">
        <mat-icon>euro</mat-icon>
        <span>Tarif Preise</span>
    </button>
    <button mat-menu-item (click)="changeColumnSet('tariffInfos')">
        <mat-icon>info</mat-icon>
        <span>Tarif Infos</span>
    </button>
</mat-menu>